<template>
     <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="predicts"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                    <v-toolbar-title>Soi Kèo</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="d-flex ma-1" style="max-width: 150px;">
                        <v-autocomplete
                            :items="fields"
                            v-model="fieldName"
                            item-text="name"
                            item-value="value"
                            label="Search Field"
                            hide-details=""
                            solo
                            outlined
                            dense
                            clear-icon="mdi-close"
                            clearable
                        >
                        </v-autocomplete>
                    </div>
                    <v-text-field
                        v-model="fieldVal"
                        label="Search"
                        outlined
                        dense
                        hide-details=""
                        append-icon="mdi-feature-search-outline"
                        @click:append="handleSearchByField"
                        class="ma-1"
                        :disabled="!fieldName"
                    >
                    </v-text-field>
                    <v-btn color="success" class="ma-1" @click="resetPageData"><v-icon>mdi-reload</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="mr-2" @click="handleDeleteMany" :disabled="!selected.length">Delete</v-btn>
                    <v-btn color="primary" @click="$router.push('/soi-keo/create')"><v-icon>mdi-plus</v-icon>Create New</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.feature_image`]="{item}">
                    <img v-if="item.feature_image" :src="item.feature_image" alt="" width="80" />
                </template>
                <template #[`item.title`]="{item}">
                    <v-list-item>{{ item.title }}</v-list-item>
                </template>
                 <template #[`item.action`]="{item}">
                    <v-btn color="primary" small @click="handleCopySc(item.id)" class="mr-2">SC</v-btn>
                    <v-btn color="orange" small @click="$router.push(`/soi-keo/${item.id}`)">Edit</v-btn>
                    <v-btn color="red" text small @click="handleDeleteLeague(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
            <div class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="currentPage" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
     </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
    },
    data () {
        return {
            headers: [
                { text: 'ID', value: 'id', align: 'center'},
                { text: 'Picture', value: 'feature_image', align: 'center', width: '15%'},
                { text: 'Title', value: 'title', align: 'center', width: '55%'},
                // { text: 'Content', value: 'content', align: 'content'},
                { text: 'Action', value: 'action', align: 'center'}
            ],
            selected: [],
            loading: false,
            fields: [
                {
                    name: 'Name', value: 'name'
                },
                {
                    name: 'Content', value: 'content'
                }
            ],
            fieldName: '',
            fieldVal: '',
            editItem: {},
            title: '',
            content: '',
            image: '',
            slug: '',
            currentPage: 1,
            per_page: 30,
            openCreateDialog: false,
            openMedia: false
        }
    },
    computed: {
        ...mapGetters('predict', {
            predicts: 'getPredictList',
            pagination: 'getPredictPagination'
        })
    },
    created () {
        this.loadPageData();
    },
    methods: {
        ...mapActions('predict', ['getPredictList', 'updatePredict', 'destroyPredict']),
        async loadPageData () {
            try {
                await this.getPredictList({
                    title: this.title,
                    content: this.content,
                    page: this.currentPage,
                    per_page: this.per_page
                })
            } catch (err) {
                console.log(err)
            }
        },
        handleSearchByField () {
            if (this.fieldVal) {
                this[this.fieldName] = this.fieldVal;
                this.loadPageData();
            }
        },
        handleDeleteMany () {
            const deleteConfirm = confirm('Are you sure want to delete, it might affect to tele sale data ?');

            if (deleteConfirm) {
                const ids = this.selected.map(item => item.id);
                this.loading = true;
                this.destroyPredict({ ids })
                .then(resp => {
                    this.loadPageData()
                    this.loading = false;
                }).catch (err => {
                    this.loading = false;
                    console.log(err)
                })
            }

        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            this[index] = item[index];

        },
        handleSaveItem (index) {
            let update = {...this.editItem, [index]: this[index] };
            this.loading = true;
            this.updatePredict(update)
                .then(resp => {
                    this.resetPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        handleDeleteLeague (id) {
             const deleteConfirm = confirm('Are you sure want to delete?');

            if (deleteConfirm) {
                this.loading = true;
                this.destroyPredict({ ids: [id] })
                .then(resp => {
                    this.loadPageData()
                    this.loading = false;
                }).catch (err => {
                    this.loading = false;
                    console.log(err)
                })
            }
        },
        resetPageData () {
            this.title = '',
            this.image = '',
            this.content = '',
            this.fieldName = '';
            this.fieldVal = '';
            this.loadPageData();
        },

        handleNewPage (currentPage) {
            this.currentPage = currentPage;
            this.loadPageData();
        },
        handleCopySc (id) {
            const text = `[soikeoct id="${id}"]`;
            navigator.clipboard.writeText(text);
            alert('copy shortcode success');
        }
    }
}
</script>

<style>

</style>

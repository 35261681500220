const baseUrl = '/api/notification';
export default axios => ({
    getNotification (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateNotification(data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createNotification(data) {
        return axios.post(baseUrl, data);
    },
    destroyNotification(data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    }
});

const baseUrl = '/api/predict';
export default axios => ({
    getPredictList (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    getPredictDetail (id) {
        return axios.get(baseUrl + '/' + id);
    },
    updatePredict (data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },

    createPredict (data) {
        return axios.post(baseUrl, data);
    },

    destroyPredict (data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    },
    getPredictMatchInfo (data) {
        return axios.post(baseUrl + '/info', data);
    }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.linkEnterLists,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Banners")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"400px"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.names,"label":"Name","hide-details":"","solo":"","background-color":"warning","dense":"","clear-icon":"mdi-close","clearable":""},on:{"change":_vm.handleSelectName},model:{value:(_vm.nameName),callback:function ($$v) {_vm.nameName=$$v},expression:"nameName"}}),_vm._v(" "),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.links,"label":"Link","hide-details":"","solo":"","background-color":"success","dense":"","clear-icon":"mdi-close","clearable":""},on:{"change":_vm.handleSelectName},model:{value:(_vm.linkName),callback:function ($$v) {_vm.linkName=$$v},expression:"linkName"}})],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.handleClearFilter()}}},[_vm._v("Clear Filter")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"purple"},on:{"click":_vm.handleCloneLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Clone")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create New")],1)],1)]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'link')},"save":function($event){return _vm.saveItem('link')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Link","single-line":""},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})]},proxy:true}],null,true)},[_c('v-list-item',{staticClass:"text-truncate",staticStyle:{"width":"200px","max-width":"100%"}},[_vm._v(_vm._s(item.link))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.saveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Name","single-line":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',{staticClass:"text-truncate",staticStyle:{"width":"200px","max-width":"100%"}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.folder_id",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(item.folder && item.folder.name ? item.folder.name : ''))])]}},{key:"item.blocked",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.blocked},on:{"click":function($event){return _vm.handleChangeFeature(item, 'blocked')}}})]}},{key:"item.feature",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.feature},on:{"click":function($event){return _vm.handleChangeFeature(item, 'feature')}}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","outlined":"","color":"accent","to":("/link/" + (item.id))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" View\n                ")],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.handleDeleteLink(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),_vm._v(" "),_c('CreateEnterLinkDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog,"folderList":_vm.folderList},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <h1>NotFound</h1>
</template>

<script>
export default {
    created () {
        window.location.href = 'https://google.com'
    }

}
</script>

<style>

</style>

<template>
    <v-dialog
        max-width="900px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create CountDown
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateTeleSaleLine">
                    <v-row>

                        <v-col cols="12">
                            <v-text-field label="Name" v-model="name" />
                        </v-col>
                        <v-col cols="12">
                            <div class="logo" style="margin-bottom: 20px;">Icon</div>
                            <v-avatar color="primary" size="36" @click="handleOpenMedia()" style="cursor: pointer;">
                                <img v-if="icon" :src="icon" alt="">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Domain" v-model="domain" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Event Time: YYYY-MM-DD H:i:s" v-model="event_time" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field label="Link" v-model="link" />
                        </v-col>

                        <v-col cols="12">
                            <div class="">Status</div>
                            <v-radio-group
                                v-model="status"
                                row
                            >
                                <v-radio
                                    label="Active"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    label="Inactive"
                                    :value="0"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="warning" type="submit">Save</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="pink" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                    <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Media from '../components/Media.vue';
export default {
    props: ['openCreateDialog'],
    components: {
        Media
    },
    data () {
        return {
            name: '',
            domain: '',
            icon: '',
            event_time: '',
            link: '',
            status: 1,
            dialog: false,
            openMedia: false
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.name = '';
                this.domain = '';
                this.icon = '';
                this.event_time = '';
                this.link = '';
                this.status = 1;
            }
        }
    },
    methods: {
        ...mapActions('countDown', ['createCountDown']),
        handleCreateTeleSaleLine () {
            if (this.name === '' || this.name.trim() === '' || this.domain === '' || this.icon === '' || this.event_time === '') {
                alert('Name, Domain, Icon, Event Time are requied');
                return false;
            }

            const data = {
                name: this.name,
                domain: this.domain,
                icon: this.icon,
                link: this.link,
                event_time: this.event_time,
                status: this.status,
            }

            this.createCountDown(data)
                .then(resp => {
                    this.$emit('close-create-dialog', true);
                })
                .catch (err => {
                    alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'err');
                })
        },
        handleOpenMedia () {
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.icon = item.image_url;
            }

        },
    }
}
</script>

<style>

</style>

import api from '../../api';
const state = {
    notificationObj: {},
}
const getters = {

    getNotificationList (state) {
        if (state.notificationObj.data && state.notificationObj.data.length) {
            return state.notificationObj.data
        }
        return []
    },
    getNotificationPagination (state) {
        if (state.notificationObj.last_page) {
            return {
                page_count: state.notificationObj.last_page,
                total: state.notificationObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_CONTACT_DOMAIN_OBJ (state, payload) {
        state.notificationObj = payload;
    }
}
const actions = {
    async getNotification({ commit }, data = {}) {
        const resp = await api.notification.getNotification(data);
        commit('SET_CONTACT_DOMAIN_OBJ', resp.data);
    },
    async updateNotification({ commit }, data = {}) {
        const resp = await api.notification.updateNotification(data);
        return resp.data
    },
    async createNotification ({ commit }, data = {}) {
        const resp = await api.notification.createNotification(data);
        return resp.data
    },
    async destroyNotification ({ commit }, data = {}) {
        const resp = await api.notification.destroyNotification(data);
        return resp.data
    },

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

<template>
    <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                        <v-toolbar-title>Contact Domain</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field
                            v-model="name_search"
                            label="Search"
                            outlined
                            dense
                            hide-details=""
                            append-icon="mdi-feature-search-outline"
                            @click:append="handleSearchName"
                            class="ma-1"
                        >
                        </v-text-field>
                        <v-btn color="red" @click="handleClearFilter"><v-icon>mdi-close</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <!-- <v-btn :disabled="!selected.length" color="primary" @click="handleClonePost" class="mr-2"><v-icon>mdi-content-copy</v-icon> Clone</v-btn> -->
                        <v-btn color="indigo" @click="handleEditAllPost" class="mr-2"><v-icon>mdi-edit</v-icon> Edit All Post</v-btn>
                        <v-btn :disabled="!selected.length" color="pink" @click="handleDeleteMany" class="mr-2">Delete</v-btn>
                        <v-btn color="primary" @click="openCreateDialog = true"><v-icon>mdi-plus</v-icon>Create Contact Domain</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.name`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.id"
                        @open="handelOpenEditItem(item, 'name')"
                        @save="handleSaveItem('name')"
                    >
                        <v-list-item>{{ item.name }}</v-list-item>
                        <template #input>
                            <v-text-field label="Name" v-model="name"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.noti_time`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.noti_time"
                        @open="handelOpenEditItem(item, 'noti_time')"
                        @save="handleSaveItem('noti_time')"
                    >
                        <v-list-item>{{ item.noti_time }}</v-list-item>
                        <template #input>
                            <v-text-field type="number" label="Noti Time(s)" v-model="noti_time"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.trigger_time`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.trigger_time"
                        @open="handelOpenEditItem(item, 'trigger_time')"
                        @save="handleSaveItem('trigger_time')"
                    >
                        <v-list-item>{{ item.trigger_time }}</v-list-item>
                        <template #input>
                            <v-text-field type="number" label="Trigger Time(s)" v-model="trigger_time"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.content`]="{item}">
                   <v-btn small color="warning" @click="handleEditContent(item)">Edit Content</v-btn>
                </template>
                <template #[`item.style`]="{item}">
                    <v-edit-dialog :return-value.sync="item.style" @open="handelOpenEditItem(item, 'style')" @save="handleSaveItem('style')">
                        <v-list-item>Style {{ item.style}}</v-list-item>
                        <template #input>
                            <v-autocomplete
                                v-model="style"
                                :items="[{ id: 1, label: 'Style 1'}, { id: 2, label: 'Style 2' }]"
                                label="Style"
                                item-text="label"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.status`]="{item}">
                    <div class="d-flex align-center justify-center">
                        <v-switch :input-value="item.status" @click="handleChangeTypeStatus(item, 'status')" />
                    </div>
                </template>
                 <template #[`item.action`]="{item}">
                    <v-btn color="red" text small @click="handleDeleteItem(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
            <div v-if="items.length" class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="current_page" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
        <CreateContactDomainDialog :openCreateDialog="openCreateDialog" @close-create-dialog="handleCloseCreateDialog" />
        <transition name="scale">
            <div class="ckeditor-modal" v-if="dialog">
                <v-card class="pa-2">
                    <v-card-text>
                        <Ckeditor v-model="content" />
                        <v-row class="mt-4">
                            <v-col>
                                <v-btn v-if="!editAllPost" color="primary" @click="handleSaveContent" block><v-icon left>mdi-content-save</v-icon> Save</v-btn>
                                <v-btn v-if="editAllPost" color="primary" @click="handleSaveAllContent" block><v-icon left>mdi-content-save</v-icon> Save</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="pink" @click="handleCancelEditContent" block>Cancel</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </transition>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateContactDomainDialog from '../components/CreateContactDomainDialog.vue';
import Ckeditor from '../components/Ckeditor.vue';
export default {
    components: {
        CreateContactDomainDialog,
        Ckeditor
    },
    data () {
        return {
            headers: [
                // { text: 'ID', value: 'id', align: 'center', sortable: true,  width: '10%' },
                { text: 'Name', value: 'name', align: 'center'},
                { text: 'Style', value: 'style', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Content', value: 'content', align: 'center'},
                { text: 'Notification Time', value: 'noti_time', align: 'center'},
                { text: 'Trigger Time', value: 'trigger_time', align: 'center'},
                { text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
            name: '',
            style: '',
            status: '',
            content: '',
            trigger_time: 4,
            noti_time: 10,
            page: 1,
            current_page: 1,
            openCreateDialog: false,
            selected: [],
            loading: false,
            name_search: '',
            loading: false,
            dialog: false,
            editItem: {},
            editAllPost: false
        }
    },
    computed: {
        ...mapGetters('contactDomain', {
            items: 'getContactDomainList',
            pagination: 'getContactDomainPagination'
        })
    },
    created () {
        this.loadPageData();
    },
    methods: {
        ...mapActions('contactDomain', ['getContactDomain', 'updateContactDomain', 'destroyContactDomain', 'createContactDomain', 'updateAllContactDomainContent']),
        async loadPageData () {
            try {
                this.loading = true;
                await this.getContactDomain({
                    per_page: this.per_page,
                    page: this.current_page
                });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err)
            }
        },
        resetPageData () {
            this.name = '';
            this.description = '';
        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            this[index] = item[index];

        },
        handleSaveItem (index) {
            let update = {...this.editItem, [index]: this[index] };
            this.loading = true;
            this.updateContactDomain(update)
                .then(resp => {
                    this.resetPageData();
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.resetPageData();
                });
        },
        handleDeleteItem (id) {
            const deleteConfirm = confirm('are you sure to delete ?');

            if (deleteConfirm) {
                this.destroyContactDomain({ ids: [id] })
                    .then(resp => {
                        this.loadPageData();
                    })
                    .catch (err => {
                        console.log(err);
                    })
            }
        },
        handleDeleteMany () {
            if (this.selected.length) {
                const deleteConfirm = confirm('are you sure to delete ?');
                const ids = this.selected.map(item => item.id);
                if (deleteConfirm) {
                    this.destroyContactDomain({ ids })
                        .then(resp => {
                            this.loadPageData();
                        })
                        .catch (err => {
                            console.log(err);
                        })
                }
            }
        },
        handleCloseCreateDialog (update) {
            this.openCreateDialog = false;
            if (update) this.loadPageData();
        },
        handleNewPage (currentPage) {
            this.current_page = currentPage;
            this.loadPageData();
        },
        // handleClonePost () {
        //     if (!this.selected.length) {
        //         alert('Pls select at least 1 row to clone');
        //         return;
        //     }

        //     this.createManySatiliteDomain(this.selected)
        //         .then(resp => {
        //             this.selected = [];
        //             this.loadPageData();
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         })
        // },
        handleClearFilter () {
            this.name_search = '';
            this.loadPageData();
        },
        handleSearchName () {
            if(this.name_search === '') alert('Pls enter keywords to search...');
            this.loadPageData();
        },
        handleChangeTypeStatus (item, key) {
            const data = { ...item, [key]: item[key] ? 0 : 1 };
            this.loading = true;
            this.updateContactDomain(data)
                .then(resp => {
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err)
                });
        },
        handleEditContent (item) {
            this.handelOpenEditItem(item, 'content');
            this.dialog = true;
        },
        handleCancelEditContent () {
            this.editItem = {};
            this.content = '';
            this.dialog = false;
            this.editAllPost = false;
        },

        async handleSaveContent () {
            try {
                await this.handleSaveItem('content');
                this.handleCancelEditContent();
            } catch (err) {
                this.handleCancelEditContent();
                alert(err.message ? err.message : 'An Error Occur');
            }
        },
        handleEditAllPost () {
            this.content = '';
            this.dialog = true;
            this.editAllPost = true;
        },
        handleSaveAllContent () {
            if (this.content === '') alert('Content can not be empty');
            this.updateAllContactDomainContent({ content: this.content }).then(resp => {
                console.log(resp);
                this.dialog = false;
                this.editAllPost = false;
                this.content = ''
                this.resetPageData();
                this.loadPageData();
            }).catch(err => {
                this.resetPageData();
                alert(err.message ? err.message : 'An Error Occur');
            })
        }
    }
}
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
}
.ckeditor-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 678px;
}
.scale-enter-active, .scale-leave-active {
  transition: all .3s;
}
.scale-enter, .scale-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

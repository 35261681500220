var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("CountDown")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSearchName},model:{value:(_vm.name_search),callback:function ($$v) {_vm.name_search=$$v},expression:"name_search"}}),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.handleClearFilter}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selected.length,"color":"primary"},on:{"click":_vm.handleClonePost}},[_c('v-icon',[_vm._v("mdi-content-copy")]),_vm._v(" Clone")],1),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selected.length,"color":"pink"},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create CountDown")],1)],1)]},proxy:true},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.domain},on:{"update:returnValue":function($event){return _vm.$set(item, "domain", $event)},"update:return-value":function($event){return _vm.$set(item, "domain", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'domain')},"save":function($event){return _vm.handleSaveItem('domain')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Domain"},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=$$v},expression:"domain"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.domain))])],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.link},on:{"update:returnValue":function($event){return _vm.$set(item, "link", $event)},"update:return-value":function($event){return _vm.$set(item, "link", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'link')},"save":function($event){return _vm.handleSaveItem('link')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Link"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.link))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('div',{staticStyle:{"max-width":"250px"}},[_c('v-list-item',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.name))])],1)])]}},{key:"item.event_time",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.event_time},on:{"update:returnValue":function($event){return _vm.$set(item, "event_time", $event)},"update:return-value":function($event){return _vm.$set(item, "event_time", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'event_time')},"save":function($event){return _vm.handleSaveItem('event_time')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Event Time: YYYY-MM-DD H:i:s"},model:{value:(_vm.event_time),callback:function ($$v) {_vm.event_time=$$v},expression:"event_time"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.event_time))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{attrs:{"input-value":item.status},on:{"click":function($event){return _vm.handleChangeTypeStatus(item, 'status')}}})],1)]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","size":"36"},on:{"click":function($event){return _vm.handleOpenMedia(item)}}},[(item.icon)?_c('img',{attrs:{"src":item.icon,"alt":""}}):_c('span',{staticClass:"white--text text-h6 text-uppercase"},[_vm._v(_vm._s(item.name.substring(0,2)))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.handleCopySc(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-duplicate")]),_vm._v("Copy SC")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.handleDeleteItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete")],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),(_vm.items.length)?_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('CreateCountDownDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('Media',{attrs:{"openMedia":_vm.openMedia},on:{"close-media":_vm.handleCloseMedia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
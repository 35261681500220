var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Notification")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSearchName},model:{value:(_vm.name_search),callback:function ($$v) {_vm.name_search=$$v},expression:"name_search"}}),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.handleClearFilter}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selected.length,"color":"pink"},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Notification")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.phone},on:{"update:returnValue":function($event){return _vm.$set(item, "phone", $event)},"update:return-value":function($event){return _vm.$set(item, "phone", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'phone')},"save":function($event){return _vm.handleSaveItem('phone')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.phone))])],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.description},on:{"update:returnValue":function($event){return _vm.$set(item, "description", $event)},"update:return-value":function($event){return _vm.$set(item, "description", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'description')},"save":function($event){return _vm.handleSaveItem('description')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.description))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{attrs:{"input-value":item.status},on:{"click":function($event){return _vm.handleChangeTypeStatus(item, 'status')}}})],1)]}},{key:"item.ago",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.ago},on:{"update:returnValue":function($event){return _vm.$set(item, "ago", $event)},"update:return-value":function($event){return _vm.$set(item, "ago", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'ago')},"save":function($event){return _vm.handleSaveItem('ago')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Time"},model:{value:(_vm.ago),callback:function ($$v) {_vm.ago=$$v},expression:"ago"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.ago))])],1)]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","size":"36"},on:{"click":function($event){return _vm.handleOpenMedia(item)}}},[(item.avatar)?_c('img',{attrs:{"src":item.avatar,"alt":""}}):_vm._e()])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){return _vm.handleDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),(_vm.items.length)?_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('CreateNotificationDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('Media',{attrs:{"openMedia":_vm.openMedia},on:{"close-media":_vm.handleCloseMedia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listTracking,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Keyword Tracking")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.per_page)+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"primary"}},_vm._l((_vm.perPageOpts),function(per){return _c('v-list-item',{key:per,on:{"click":function($event){return _vm.handleSelectPerPage(per)}}},[_vm._v("\n                                    "+_vm._s(per)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.currentOptLink.name ? _vm.currentOptLink.name : 'Time Range')+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"primary"}},_vm._l((_vm.dropItemLinks),function(opt,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleSelectTimeRangeOpt(opt)}}},[_vm._v("\n                                    "+_vm._s(opt.name)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.browserName ? _vm.browserName : 'Browser')+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"success"}},_vm._l((_vm.browser),function(b){return _c('v-list-item',{key:b,on:{"click":function($event){return _vm.handleSelectBrowser(b)}}},[_vm._v("\n                                    "+_vm._s(b)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"warning","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.deviceName ? _vm.deviceName : 'Device')+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"warning"}},_vm._l((_vm.device),function(de){return _c('v-list-item',{key:de,on:{"click":function($event){return _vm.handleSelectDevice(de)}}},[_vm._v("\n                                    "+_vm._s(de)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"info","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.osName ? _vm.osName : 'Os')+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"info"}},_vm._l((_vm.os_platform),function(os){return _c('v-list-item',{key:os,on:{"click":function($event){return _vm.handleSelectOs(os)}}},[_vm._v("\n                                    "+_vm._s(os)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"purple","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n                                    "+_vm._s(_vm.keywordName ? _vm.keywordName: 'Keyword')+"\n                                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n                                        mdi-chevron-down\n                                    ")])],1)]}}])},[_vm._v(" "),_c('v-list',{staticClass:"fix-height",attrs:{"color":"purple"}},_vm._l((_vm.keyword),function(keyw){return _c('v-list-item',{key:keyw,on:{"click":function($event){return _vm.handleSelectKeyword(keyw)}}},[_vm._v("\n                                    "+_vm._s(keyw)+"\n                                ")])}),1)],1),_vm._v(" "),_c('v-text-field',{staticClass:"mr-4",staticStyle:{"max-width":"250px"},attrs:{"outlined":"","label":"Referal","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSelectReferal},model:{value:(_vm.referalName),callback:function ($$v) {_vm.referalName=$$v},expression:"referalName"}}),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.handleClearFilter}},[_c('v-icon',[_vm._v("mdi-cancel")])],1)],1)]},proxy:true},{key:"item.referal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"250px","width":"100%: text-align:center","margin":"0 auto","cursor":"pointer","over-flow":"hidden"},attrs:{"title":item.referal}},[_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(item.referal),expression:"item.referal"}],staticClass:"text-lowercase",attrs:{"small":"","color":"indigo"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_vm._v(_vm._s(item.referal))],1)],1)]}},{key:"item.keyword",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"150px","width":"100%","margin":"0 auto"}},[_c('v-list-item',{staticClass:"text-truncate"},[_vm._v("\n                            "+_vm._s(item.keyword && item.keyword.name ? item.keyword.name : '')+"\n                        ")])],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                    Total: "+_vm._s(_vm.pagination.total)+"\n                ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
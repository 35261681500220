<template>
    <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                        <v-toolbar-title>CountDown</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field
                            v-model="name_search"
                            label="Search"
                            outlined
                            dense
                            hide-details=""
                            append-icon="mdi-feature-search-outline"
                            @click:append="handleSearchName"
                            class="ma-1"
                        >
                        </v-text-field>
                        <v-btn color="red" @click="handleClearFilter"><v-icon>mdi-close</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!selected.length" color="primary" @click="handleClonePost" class="mr-2"><v-icon>mdi-content-copy</v-icon> Clone</v-btn>
                        <v-btn :disabled="!selected.length" color="pink" @click="handleDeleteMany" class="mr-2">Delete</v-btn>
                        <v-btn color="primary" @click="openCreateDialog = true"><v-icon>mdi-plus</v-icon>Create CountDown</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.domain`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.domain"
                        @open="handelOpenEditItem(item, 'domain')"
                        @save="handleSaveItem('domain')"
                    >
                        <v-list-item>{{ item.domain }}</v-list-item>
                        <template #input>
                            <v-text-field label="Domain" v-model="domain"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.link`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.link"
                        @open="handelOpenEditItem(item, 'link')"
                        @save="handleSaveItem('link')"
                    >
                        <v-list-item>{{ item.link }}</v-list-item>
                        <template #input>
                            <v-text-field label="Link" v-model="link"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.name`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.name"
                        @open="handelOpenEditItem(item, 'name')"
                        @save="handleSaveItem('name')"
                    >
                        <div style="max-width: 250px;">
                            <v-list-item class="text-truncate">{{ item.name }}</v-list-item>
                        </div>
                        <template #input>
                            <v-text-field label="Name" v-model="name"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.event_time`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.event_time"
                        @open="handelOpenEditItem(item, 'event_time')"
                        @save="handleSaveItem('event_time')"
                    >
                        <v-list-item>{{ item.event_time }}</v-list-item>
                        <template #input>
                            <v-text-field label="Event Time: YYYY-MM-DD H:i:s" v-model="event_time"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template #[`item.status`]="{item}">
                    <div class="d-flex align-center justify-center">
                        <v-switch :input-value="item.status" @click="handleChangeTypeStatus(item, 'status')" />
                    </div>
                </template>
                <template #[`item.icon`]="{item}">
                    <v-avatar color="primary" size="36" @click="handleOpenMedia(item)" style="cursor: pointer;">
                        <img v-if="item.icon" :src="item.icon" alt="">
                        <span v-else class="white--text text-h6 text-uppercase">{{ item.name.substring(0,2) }}</span>
                    </v-avatar>
                </template>
                 <template #[`item.action`]="{item}">
                    <v-btn color="primary" small @click="handleCopySc(item.id)" class="mr-2"><v-icon left>mdi-content-duplicate</v-icon>Copy SC</v-btn>
                    <v-btn color="red" small @click="handleDeleteItem(item.id)"><v-icon left>mdi-delete</v-icon> Delete</v-btn>
                </template>
            </v-data-table>
            <div v-if="items.length" class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="current_page" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
        <CreateCountDownDialog :openCreateDialog="openCreateDialog" @close-create-dialog="handleCloseCreateDialog" />
        <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateCountDownDialog from '../components/CreateCountDownDialog.vue';
import Media from '../components/Media.vue';
export default {
    components: {
        CreateCountDownDialog,
        Media
    },
    data () {
        return {
            headers: [
                // { text: 'ID', value: 'id', align: 'center', sortable: true,  width: '10%' },
                { text: 'Domain', value: 'domain', align: 'center'},
                { text: 'Name', value: 'name', align: 'center'},
                { text: 'Icon', value: 'icon', align: 'center'},
                { text: 'Time', value: 'event_time', align: 'center'},
                { text: 'Link', value: 'link', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
            name: '',
            domain: '',
            icon: '',
            link: '',
            event_time: '',
            status: '',
            page: 1,
            current_page: 1,
            openCreateDialog: false,
            selected: [],
            loading: false,
            name_search: '',
            openMedia: false
        }
    },
    computed: {
        ...mapGetters('countDown', {
            items: 'getCountDownList',
            pagination: 'getCountDownPagination'
        })
    },
    created () {
        this.loadPageData();
    },
    methods: {
        ...mapActions('countDown', ['getCountDown', 'updateCountDown', 'destroyCountDown', 'createCountDown', 'createManyCountDown']),
        async loadPageData () {
            try {
                this.loading = true;
                await this.getCountDown({
                    per_page: this.per_page,
                    page: this.current_page,
                    domain: this.name_search
                });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err)
            }
        },
        resetPageData () {
            this.name = '';
            this.domain = '';
            this.icon = '';
            this.link = '';
            this.event_time = '';
            this.status = '';
        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            this[index] = item[index];

        },
        handleSaveItem (index) {
            let update = {...this.editItem, [index]: this[index] };
            this.loading = true;
            this.updateCountDown(update)
                .then(resp => {
                    this.resetPageData();
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.resetPageData();
                });
        },
        handleDeleteItem (id) {
            const deleteConfirm = confirm('are you sure to delete ?');

            if (deleteConfirm) {
                this.destroyCountDown({ ids: [id] })
                    .then(resp => {
                        this.loadPageData();
                    })
                    .catch (err => {
                        console.log(err);
                    })
            }
        },
        handleDeleteMany () {
            if (this.selected.length) {
                const deleteConfirm = confirm('are you sure to delete ?');
                const ids = this.selected.map(item => item.id);
                if (deleteConfirm) {
                    this.destroyCountDown({ ids })
                        .then(resp => {
                            this.loadPageData();
                        })
                        .catch (err => {
                            console.log(err);
                        })
                }
            }
        },
        handleCloseCreateDialog (update) {
            this.openCreateDialog = false;
            if (update) this.loadPageData();
        },
        handleNewPage (currentPage) {
            this.current_page = currentPage;
            this.loadPageData();
        },
        handleClonePost () {
            if (!this.selected.length) {
                alert('Pls select at least 1 row to clone');
                return;
            }

            this.createManyCountDown(this.selected)
                .then(resp => {
                    this.selected = [];
                    this.loadPageData();
                })
                .catch(err => {
                    console.log(err);
                })
        },
        handleClearFilter () {
            this.name_search = '';
            this.loadPageData();
        },
        handleSearchName () {
            if(this.name_search === '') alert('Pls enter keywords to search...');
            this.loadPageData();
        },
        handleOpenMedia (item) {
            this.editItem = item;
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.icon = item.image_url;
                this.handleSaveItem('icon');
            }

        },
        handleChangeTypeStatus (item, key) {
            const data = { ...item, [key]: item[key] ? 0 : 1 };
            this.loading = true;
            this.updateCountDown(data)
                .then(resp => {
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err)
                });
        },
        handleCopySc (id) {
            const text = `[et-countdown id="${id}"]`;
            navigator.clipboard.writeText(text);
            alert('copy shortcode success');
        }
    }
}
</script>

<style scoped>
</style>

import api from '../../api';
const state = {

    goldPriceObj: {},
}

const getters = {
    getGoldPriceList (state) {
        if (state.goldPriceObj.data && state.goldPriceObj.data.length) {
            return state.goldPriceObj.data
        }
        return []
    },
    getGoldPriceListPagination (state) {
        if (state.goldPriceObj.last_page) {
            return {
                page_count: state.goldPriceObj.last_page,
                total: state.goldPriceObj.total,
            }
        }
        return {}
    }
}

const mutations = {
    SET_GOLD_PRICE (state, payload) {
        state.goldPriceObj = payload
    },
}

const actions = {

    async getGoldPrice ({ commit }, params = {}) {
        const resp = await api.goldPrice.getGoldPrice(params);
        commit ('SET_GOLD_PRICE', resp.data);
        return resp.data
    },

    async updateGoldPrice ({ commit }, params = {}) {
        const resp = await api.goldPrice.updateGoldPrice(params);
        return resp.data;
    },
    async deleteGoldPrice ({ commit }, params = {}) {
        const resp = await api.goldPrice.deleteGoldPrice(params);
        return resp.data;
    },

    async createGoldPrice ({ commit }, data = {}) {
        const resp = await api.goldPrice.createGoldPrice(data);
        return resp.data;
    },


}

export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

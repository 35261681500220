var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Contact Domain")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSearchName},model:{value:(_vm.name_search),callback:function ($$v) {_vm.name_search=$$v},expression:"name_search"}}),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.handleClearFilter}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo"},on:{"click":_vm.handleEditAllPost}},[_c('v-icon',[_vm._v("mdi-edit")]),_vm._v(" Edit All Post")],1),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selected.length,"color":"pink"},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Contact Domain")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.noti_time",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.noti_time},on:{"update:returnValue":function($event){return _vm.$set(item, "noti_time", $event)},"update:return-value":function($event){return _vm.$set(item, "noti_time", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'noti_time')},"save":function($event){return _vm.handleSaveItem('noti_time')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Noti Time(s)"},model:{value:(_vm.noti_time),callback:function ($$v) {_vm.noti_time=$$v},expression:"noti_time"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.noti_time))])],1)]}},{key:"item.trigger_time",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.trigger_time},on:{"update:returnValue":function($event){return _vm.$set(item, "trigger_time", $event)},"update:return-value":function($event){return _vm.$set(item, "trigger_time", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'trigger_time')},"save":function($event){return _vm.handleSaveItem('trigger_time')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Trigger Time(s)"},model:{value:(_vm.trigger_time),callback:function ($$v) {_vm.trigger_time=$$v},expression:"trigger_time"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.trigger_time))])],1)]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.handleEditContent(item)}}},[_vm._v("Edit Content")])]}},{key:"item.style",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.style},on:{"update:returnValue":function($event){return _vm.$set(item, "style", $event)},"update:return-value":function($event){return _vm.$set(item, "style", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'style')},"save":function($event){return _vm.handleSaveItem('style')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-autocomplete',{attrs:{"items":[{ id: 1, label: 'Style 1'}, { id: 2, label: 'Style 2' }],"label":"Style","item-text":"label","item-value":"id"},model:{value:(_vm.style),callback:function ($$v) {_vm.style=$$v},expression:"style"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v("Style "+_vm._s(item.style))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{attrs:{"input-value":item.status},on:{"click":function($event){return _vm.handleChangeTypeStatus(item, 'status')}}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){return _vm.handleDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),(_vm.items.length)?_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('CreateContactDomainDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('transition',{attrs:{"name":"scale"}},[(_vm.dialog)?_c('div',{staticClass:"ckeditor-modal"},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-text',[_c('Ckeditor',{model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_vm._v(" "),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[(!_vm.editAllPost)?_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.handleSaveContent}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save")],1):_vm._e(),_vm._v(" "),(_vm.editAllPost)?_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.handleSaveAllContent}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Save")],1):_vm._e()],1),_vm._v(" "),_c('v-col',[_c('v-btn',{attrs:{"color":"pink","block":""},on:{"click":_vm.handleCancelEditContent}},[_vm._v("Cancel")])],1)],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.plugins,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Plugin Repository")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Plugin")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'author')},"save":function($event){return _vm.handleSaveItem('author')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit author"},model:{value:(_vm.author),callback:function ($$v) {_vm.author=$$v},expression:"author"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.author))])],1)]}},{key:"item.author_profile",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'author_profile')},"save":function($event){return _vm.handleSaveItem('author_profile')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Author Profile"},model:{value:(_vm.author_profile),callback:function ($$v) {_vm.author_profile=$$v},expression:"author_profile"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.author_profile))])],1)]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'version')},"save":function($event){return _vm.handleSaveItem('version')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Version"},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.version))])],1)]}},{key:"item.requires",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'requires')},"save":function($event){return _vm.handleSaveItem('requires')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Requires"},model:{value:(_vm.requires),callback:function ($$v) {_vm.requires=$$v},expression:"requires"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.requires))])],1)]}},{key:"item.tested",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'tested')},"save":function($event){return _vm.handleSaveItem('tested')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit tested"},model:{value:(_vm.tested),callback:function ($$v) {_vm.tested=$$v},expression:"tested"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.tested))])],1)]}},{key:"item.requires_php",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'requires_php')},"save":function($event){return _vm.handleSaveItem('requires_php')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Require Php"},model:{value:(_vm.requires_php),callback:function ($$v) {_vm.requires_php=$$v},expression:"requires_php"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.requires_php))])],1)]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'slug')},"save":function($event){return _vm.handleSaveItem('slug')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Slug"},model:{value:(_vm.slug),callback:function ($$v) {_vm.slug=$$v},expression:"slug"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.slug))])],1)]}},{key:"item.download_url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"color":"warning","href":item.download_url + '?ver=' + item.version,"small":""}},[_vm._v("Download")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"pink","small":""},on:{"click":function($event){return _vm.handleClickUpload(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-upload")]),_vm._v(" Upload")],1)]}}],null,true)})],1),_vm._v(" "),_c('input',{ref:"pluginFile",attrs:{"type":"file","hidden":""},on:{"change":_vm.handleUploadPlugin}}),_vm._v(" "),_c('CreatePluginRepoDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog
        max-width="900px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create Contact Domain
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateTeleSaleLine">
                    <v-row>

                        <v-col cols="12">
                            <v-text-field label="Name" v-model="name" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Notification Time" v-model="noti_time" />
                        </v-col>

                        <v-col cols="12">
                            <div class="">Style</div>
                            <v-radio-group
                                v-model="style"
                                row
                            >
                                <v-radio
                                    label="Style 1"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    label="Style 2"
                                    :value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="">Status</div>
                            <v-radio-group
                                v-model="status"
                                row
                            >
                                <v-radio
                                    label="Active"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    label="Inactive"
                                    :value="0"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="warning" type="submit">Save</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="pink" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: ['openCreateDialog'],
    data () {
        return {
            name: '',
            style: 1,
            status: 1,
            noti_time: '',
            dialog: false,
            openMedia: false
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.noti_time = 10;
                this.name = '';
                this.style = 1;
                this.status = 1;
            }
        }
    },
    methods: {
        ...mapActions('contactDomain', ['createContactDomain']),
        handleCreateTeleSaleLine () {
            if (this.name === '' || this.name.trim() === '') {
                alert('Name is requied');
                return false;
            }

            const data = {
                name: this.name,
                style: this.style,
                status: this.status,
                noti_time: this.noti_time
            }

            this.createContactDomain(data)
                .then(resp => {
                    this.$emit('close-create-dialog', true);
                })
                .catch (err => {
                    alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'err');
                })
        },
    }
}
</script>

<style>

</style>

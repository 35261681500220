<template>
    <div class="top-list-wrap">
        <v-row>
            <v-col cols="8">
                <v-autocomplete
                    v-model="lists"
                   :items="listFolders"
                    hide-no-data
                    item-text="name"
                    item-value="id"
                    placeholder="Search Bookie"
                    multiple
                    :chips="true"
                    :deletable-chips="true"
                    :loading="loading"
                    label="Select Bookie"
                />
            </v-col>
            <v-col cols="4">
                <v-btn block color="orange" class="mb-2" @click="handleLoadTopList">Load Top List</v-btn>
                <v-btn block color="primary" @click="handleCreateTopList" :disabled="!lists.length">Create Top List</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <div class="top-list" v-if="items && items.length">
                <draggable v-model="items">
                    <transition-group>
                        <div class="top-list-item" v-for="(item, index) in items" :key="item.folder_id + '-' + index">
                            <div class="number">
                                {{ index + 1 }}
                            </div>
                            <div class="logo">
                                <a :href="item.link" target="_blank">
                                    <img :src="item.folder.logo" alt="">
                                </a>
                                <div style="text-align: center;">{{ item.folder.name }}</div>
                            </div>
                            <div class="promotion" v-html="item.promotion">
                            </div>
                            <div class="review" v-html="item.review">
                            </div>
                            <div class="action">
                                <v-btn color="success" class="mb-2">{{ item.link_text }}</v-btn>
                                <v-btn color="pink">{{ item.action_link_text }}</v-btn>
                            </div>
                            <div class="tool">
                                <v-btn small color="orange" @click="handleEditItem(item)" class="mb-2"><v-icon left>mdi-pencil</v-icon> Edit</v-btn>
                                <v-btn small color="red" @click="handleCloneItem(item)" class="mb-2"><v-icon left>mdi-content-duplicate</v-icon> Clone</v-btn>
                            </div>
                            <div class="delete">
                                <v-btn small color="red" @click="handleDeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                            </div>
                        </div>
                    </transition-group>
                </draggable>

            </div>
        </v-row>
        <v-row>
             <v-col cols="12">
                <v-btn block color="orange" @click="handleSaveTopList" :loading="saveLoading">Save Top List</v-btn>
            </v-col>
        </v-row>
        <!-- <Media :openMedia="openMedia" @close-media="handleCloseMedia" /> -->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="768px"
        >
            <v-card>
                <v-card-title>
                    Create Top List
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <div class="logo">Logo</div>
                            <div class="top-avatar">
                                <div>+</div>
                                <img  v-if="editItem.folder && editItem.folder.logo" :src="editItem.folder.logo" alt="" width="100%" height="100%">
                            </div>
                            <div class="name">{{  editItem.folder && editItem.folder.name ? editItem.folder.name : '' }}</div>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                :items="listFolders"
                                hide-no-data
                                item-text="name"
                                item-value="id"
                                return-object
                                placeholder="Search Bookie"
                                :chips="true"
                                :deletable-chips="true"
                                @change="handleChangeBookie"
                                 label="Select Bookie"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Link" v-model="editItem.link"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Link text" v-model="editItem.link_text"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Action Link" v-model="editItem.action_link"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Action Link Text" v-model="editItem.action_link_text"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Promotion</div>
                            <vue-editor
                                id="editor"
                                useCustomImageHandler

                                v-model="editItem.promotion"
                                >
                            </vue-editor>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Review</div>
                            <vue-editor
                                id="editor2"
                                useCustomImageHandler

                                v-model="editItem.review"
                                >
                            </vue-editor>
                        </v-col>
                        <v-col cols="12">
                            <div class="editor-title">Custom Style</div>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Number color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.ranking_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu1" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.ranking_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu1 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.ranking_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Notice bg color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.notice_bg_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu2" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.notice_bg_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu2 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.notice_bg_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Notice text color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.notice_text_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu10" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.notice_text_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu10 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.notice_text_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Promotion text color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.pt_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu3" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.pt_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu3 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.pt_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Review bg color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.review_bg_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu4" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.review_bg_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu4 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.review_bg_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Review text color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.review_text_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu5" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.review_text_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu5 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.review_text_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Playnow  color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.playnow_bg_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu6" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.playnow_bg_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu6 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.playnow_bg_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Playnow text color</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.playnow_text_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu7" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.playnow_text_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu7 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.playnow_text_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Background color 1</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.bg_color_one">
                                <template v-slot:append>
                                    <v-menu v-model="menu8" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.bg_color_one}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu8 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.bg_color_one" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <div class="editor-title">Background color 2</div>
                            <v-text-field label="Number color" solo dense v-model="editItem.bg_color_two">
                                <template v-slot:append>
                                    <v-menu v-model="menu9" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.bg_color_two}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu9 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.bg_color_two" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Brand Color</div>
                            <v-text-field label="Brand color" solo dense v-model="editItem.brand_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu11" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.brand_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu11 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.brand_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <div class="editor-title">Review Number Color</div>
                            <v-text-field label="Review Number Color" solo dense v-model="editItem.review_num_color">
                                <template v-slot:append>
                                    <v-menu v-model="menu12" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" :style="`background-color: ${editItem.review_num_color}; cursor: pointer; height: 30px; width: 30px; border-radius: ${menu12 ? '50%' : '4px'}; transition: border-radius 200ms ease-in-out;`" />
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker v-model="editItem.review_num_color" flat />
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">

                            <v-btn block color="primary" @click="handleSyncColor" :loading="fakeLoading">Sync bộ mã màu cho toàn list</v-btn>
                        </v-col>
                        <v-col cols="6">

                        </v-col>

                        <v-col cols="6">
                            <v-btn block color="primary" @click="handleAddItem">{{ isEdit ? 'Edit' : 'Add' }}</v-btn>
                        </v-col>
                         <v-col cols="6">
                            <v-btn block color="orange" @click="dialog = false; isEdit = false; editItem = {}">Close</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
// import Media from './Media.vue'
import { mapActions } from 'vuex';
import draggable from 'vuedraggable'
export default {
    props: ['listItems', 'topId', 'saveLoading'],
    components: {
        VueEditor,
        // Media,
        draggable
    },
    data () {
        return {
            items: this.listItems,
            image: '',
            link: null,
            link_text: null,
            action_link: null,
            action_link_text: null,
            promotion: null,
            review: null,
            openMedia: false,
            name: null,
            dialog: false,
            isEdit: false,
            id: null,
            lists: [],
            listFolders: [],
            loading: false,
            folder: {},
            folder_id: null,
            editItem: {},
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            menu7: false,
            menu8: false,
            menu9: false,
            menu10: false,
            menu11: false,
            menu12: false,
            fakeLoading: false
        }
    },
    computed: {
        // listItems() {
        //     return this.items
        // }
    },
    watch: {
        listItems (val) {
            this.items = val;
        }
    },
    methods: {
        ...mapActions('topList', ['updateTopList', 'getTopList', 'getTopListFolder']),
        handleSyncColor () {
            const syncConfirm = confirm('Are you sure to sync color for all top list ?');

            if (syncConfirm) {
                this.fakeLoading = true;
                this.items = this.items.map(item => {
                    return {...item, ranking_color: this.editItem.ranking_color, notice_bg_color: this.editItem.notice_bg_color, pt_color: this.editItem.pt_color, review_bg_color: this.editItem.review_bg_color, review_text_color: this.editItem.review_text_color, playnow_bg_color: this.editItem.playnow_bg_color, playnow_text_color: this.editItem.playnow_text_color, bg_color_one: this.editItem.bg_color_one, bg_color_two: this.editItem.bg_color_two, notice_text_color: this.editItem.notice_text_color, brand_color: this.editItem.brand_color, review_num_color: this.editItem.review_num_color }
                });

                setTimeout(() => {
                    this.fakeLoading = false;
                }, 1500)
            }

        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item) this.image = item.image_url;
        },
        handleAddItem () {
            if (this.isEdit) {
                this.items = this.items.map(check => {
                    if (check.id === this.editItem.id) {
                        const newItem = { ...check, ...this.editItem }
                        return newItem
                    }
                    return check
                })
            }
            else {
                this.items = [...this.items, item];
            }
            this.isEdit = false;
            this.dialog = false;
            this.editItem = {};
        },
        handleDeleteItem (item) {
            this.$emit('destroy-toplist', { ids: [item.id] });
            if (this.listFolders.length)
                this.listFolders = [...this.listFolders, item.folder];
        },
        handleEditItem (item) {
            this.editItem = {...item};

            this.dialog = true;
            this.isEdit = true;
        },
        handleCloneItem (item) {
            const newItem = {...item, id:  Math.floor(Math.random() * 100) + '-' + Date.now()}
            this.items = [newItem, ...this.items];
        },
        async handleSaveTopList () {
            if (!this.items.length) {
                alert('There is no list');
                return;
            }
            this.$emit('save-toplist', { items: this.items, topId: this.topId });
        },
        async handleLoadTopList () {
            try {
                this.loading = true;
                const resp = await this.getTopListFolder({ per_page: 1000 });
                const ids = this.items.map(item => item.folder_id);
                const folders = resp.data.filter(item => ids.includes(item.id) === false)
                this.listFolders = folders;
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err);
            }
        },
        handleCreateTopList () {
            if (!this.lists.length) return;
            this.$emit('create-toplist', { topId: this.topId, items: this.lists });
            const folderIds = [];
            this.lists.forEach(list => {
                folderIds.push(list.id);
            })
            this.listFolders = this.listFolders.filter(folder => !folderIds.includes(folder.id))
            this.lists = [];
        },
        handleChangeBookie (item) {
            if (item) {
                this.editItem.folder = item;
                this.editItem.folder_id = item.id
            }

        }
    }
}
</script>
<style scoped lang="scss">
.top-avatar {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: #ccc;
    position: relative;
    cursor: pointer;
    margin: 10px 10px 10px 0;
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-weight: 700;
        z-index:9;
    }
    img {
        object-fit:cover;
        z-index: 10;
        position: relative;
    }
}
.editor-title {
    margin-bottom: 0.6rem;
}
.top-list {
    margin: 1rem 0;
    width: 100%;
}
.top-list-item {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 1fr 1fr;
    grid-gap: 10px;
}
.top-list-item .logo, .top-list-item .action, .top-list-item .tool, .top-list-item .delete, .top-list-item .number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

 .top-list-item .logo a {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    img {
        width: 100%;
        height: 100%;
    }
 }
  .top-list-item .logo div {
    font-size: 20px;
    font-weight: 700;
  }
  .top-list-wrap {
    padding: 1rem;
  }
  .top-list-item {
    width: 100%;
    background: rgb(204 204 204 / 20%);
    padding: 4px;
    border-radius: 4px;
    margin-bottom: 1rem;
    cursor: move;
  }
   .top-list-item .number {
    font-size: 20px;
    font-weight: 700;
   }
</style>

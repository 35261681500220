const baseUrl = '/api/countdown';
export default axios => ({
    getCountDown (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateCountDown(data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createCountDown(data) {
        return axios.post(baseUrl, data);
    },
    createManyCountDown(data) {
        return axios.post(baseUrl + '/create', data);
    },
    destroyCountDown(data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    }
});

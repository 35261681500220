const baseUrl = '/api/keycode';
export default axios => ({
    getKeyCode (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateKeyCode(data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createKeyCode(data) {
        return axios.post(baseUrl, data);
    },
    destroyKeyCode(data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    }
});

const baseUrl = '/api/league';
export default axios => ({
    getLeagueList (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateLeague (data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createLeague (data) {
        return axios.post(baseUrl, data);
    },
    getLeagueFilter (data = {}) {
        return axios.get(baseUrl + '/filter');
    },
    destroyLeague (data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    }
});

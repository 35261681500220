<template>
    <v-dialog
        max-width="900px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create Notification
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateTeleSaleLine">
                    <v-row>
                        <v-col cols="12">
                            <div class="logo" style="margin-bottom: 20px;">Avatar</div>
                            <v-avatar color="primary" size="36" @click="handleOpenMedia()" style="cursor: pointer;">
                                <img v-if="avatar" :src="avatar" alt="">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Name" v-model="name" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Phone" v-model="phone" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Description" v-model="description" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Time" v-model="ago" />
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="warning" type="submit">Save</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="pink" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                    <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Media from '../components/Media.vue';
export default {
    props: ['openCreateDialog'],
    components: {
        Media
    },
    data () {
        return {
            name: '',
            description: '',
            avatar: '',
            phone: '',
            ago: '',
            dialog: false,
            openMedia: false
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.name = '';
                this.description = '';
                this.avatar = '';
                this.phone = '';
                this.ago = '';
            }
        }
    },
    methods: {
        ...mapActions('notification', ['createNotification']),
        handleCreateTeleSaleLine () {
            if (this.name === '' || this.name.trim() === '' ) {
                alert('Name, Anchortext, Link, Random Links are requied');
                return false;
            }

            const data = {
                name: this.name,
                description: this.description,
                avatar: this.avatar,
                phone: this.phone,
                ago: this.ago,
            }

            this.createNotification(data)
                .then(resp => {
                    this.$emit('close-create-dialog', true);
                })
                .catch (err => {
                    alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'err');
                })
        },
        handleOpenMedia () {
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.avatar = item.image_url;
            }

        },
    }
}
</script>

<style>

</style>

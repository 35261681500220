import api from '../../api';
const state = {
    topListObj: {},
}
const getters = {

    getTopList (state) {
        if (state.topListObj.data && state.topListObj.data.length) {
            return state.topListObj.data
        }
        return []
    },
    getTopListPaination (state) {
        if (state.topListObj.last_page) {
            return {
                page_count: state.topListObj.last_page,
                total: state.topListObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_TOP_LIST (state, payload) {
        state.topListObj = payload;
    },
    SET_TOP_LIST_DATA (state, payload) {
        const { editItem, topId } = payload;
        const check = [];

        state.topListObj.data = state.topListObj.data.map(item => {
            if (item.id === topId) {
                item.items.map(it => {
                    if (it.id = editItem.id) {
                        return editItem;
                    }

                    return it;
                })
            }
            return item
        })
    }
}
const actions = {
    async getTopList({ commit }, data = {}) {
        const resp = await api.topList.getTopList(data);
        commit('SET_TOP_LIST', resp.data);
    },
    async updateTopList ({ commit }, data = {}) {
        const resp = await api.topList.updateTopList(data);
        return resp.data
    },
    async createTopList ({ commit }, data = {}) {
        const resp = await api.topList.createTopList(data);
        return resp.data
    },
    async destroyTopList ({ commit }, data = {}) {
        const resp = await api.topList.destroyTopList(data);
        return resp.data
    },
    async getTopListFolder({ commit }, data = {}) {
        const resp = await api.topList.getTopListFolder(data);
        return resp.data
    },
    async saveTopListItem ({ commit }, data = {}) {
        const resp = await api.topList.saveTopListItem(data);
        return resp.data
    },
    async createTopListItem ({ commit }, data = {}) {
        const resp = await api.topList.createTopListItem(data);
        return resp.data
    },
    async destroyTopListItem  ({ commit }, data = {}) {
        const resp = await api.topList.destroyTopListItem(data);
        return resp.data
    },
    setTopListState({ commit }, data = {}) {
        commit('SET_TOP_LIST_DATA', data);
    }

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categoryList,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Categories")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"400px"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.descriptions,"label":"Description","hide-details":"","solo":"","background-color":"purple","dense":"","clear-icon":"mdi-close","clearable":""},on:{"change":_vm.handleSelectDescription},model:{value:(_vm.descriptionName),callback:function ($$v) {_vm.descriptionName=$$v},expression:"descriptionName"}}),_vm._v(" "),_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.names,"label":"Name","hide-details":"","solo":"","background-color":"warning","dense":"","clear-icon":"mdi-close","clearable":""},on:{"change":_vm.handleSelectName},model:{value:(_vm.nameName),callback:function ($$v) {_vm.nameName=$$v},expression:"nameName"}})],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.handleClearFilter()}}},[_vm._v("Clear Filter")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary mr-2"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create New")],1)],1)]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'description')},"save":function($event){return _vm.saveItem('description')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Description","single-line":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.description))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.saveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Name","single-line":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.open_new_tab",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.open_new_tab ? true : false,"label":"On"},on:{"change":function($event){return _vm.handleChangeOpenTab($event, item)}}})]}},{key:"item.open_links",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.open_links},on:{"update:returnValue":function($event){return _vm.$set(item, "open_links", $event)},"update:return-value":function($event){return _vm.$set(item, "open_links", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'open_links')},"save":function($event){return _vm.saveItem('open_links')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit open links","single-line":""},model:{value:(_vm.open_links),callback:function ($$v) {_vm.open_links=$$v},expression:"open_links"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.open_links))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","to":("/category/" + (item.id)),"color":"pink"}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" View")],1),_vm._v(" "),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.handleCopyScript(item.script_url)}}},[_vm._v("Copy Script")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.handleDestroyDirectory(item.id)}}},[_vm._v("Delete")])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1),_vm._v(" "),_c('CreateLinkDirectoryDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("List User")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create User")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name ? item.name : 'No Name'))])],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'email')},"save":function($event){return _vm.handleSaveItem('email')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.email ? item.email : 'No Email'))])],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'roles')},"save":function($event){return _vm.handleSaveItem('role_ids')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-card',{attrs:{"width":"500px","elevation":"0"}},[_c('v-card-title',[_vm._v("\n                                Edit Role\n                            ")]),_vm._v(" "),_c('v-card-text',{staticClass:"fix-height mb-4",staticStyle:{"max-height":"600px"}},[_c('v-row',_vm._l((_vm.roles),function(rol){return _c('v-col',{key:rol.id,attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":rol.name,"color":"red","value":rol.id,"hide-details":""},model:{value:(_vm.role_ids),callback:function ($$v) {_vm.role_ids=$$v},expression:"role_ids"}})],1)}),1)],1)],1)]},proxy:true}],null,true)},[(item.roles.length)?_c('div',_vm._l((item.roles),function(role){return _c('v-chip',{key:role.id,staticClass:"ma-2",attrs:{"small":""}},[_vm._v("\n                            "+_vm._s(role.name)+"\n                        ")])}),1):_c('v-list-item',[_vm._v("No Role")])],1)]}},{key:"item.password",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'password')},"save":function($event){return _vm.handleSaveItem('password')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v("*****")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.handleDeleteUser(item.id)}}},[_vm._v("Delete")])]}}],null,true)})],1),_vm._v(" "),_c('CreateUserDialog',{attrs:{"roles":_vm.roles,"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
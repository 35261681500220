<template>
    <div id="APP">
        <Private  v-if="isPrivate"/>
        <Public  v-if="!isPrivate" />
    </div>
</template>

<script>
import Private from './components/Private.vue';
import Public from './components/Public.vue';

export default {
    components:{
        Private,
        Public
    },
    computed: {
        isPrivate() {
			var arrRouter = ['getcode'];
			var routerName = this.$route.name;
			if(arrRouter.includes(routerName)) return false;
			return true;
		},
    },
}
</script>

<style>

</style>

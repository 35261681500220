<template>
    <v-dialog
        max-width="600px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create Top List
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateUser">
                    <v-text-field label="Name" v-model="name"></v-text-field>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block type="submit">Save</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn outlined color="warning" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: ['roles', 'openCreateDialog'],
    data () {
        return {
            name: '',
            status: 1,
            dialog: false
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.name = '';
                this.status = 1;
            }
        }
    },
    methods: {
        ...mapActions('topList', ['createTopList']),
        handleCreateUser () {
            if (this.name === '') {
                alert('name are requied');
                return false;
            }

            const data = {
                name: this.name,
                status: this.status
            }

            this.createTopList(data)
                .then(resp => {
                    this.$emit('close-create-dialog', true);
                })
                .catch (err => {
                    alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'err');
                })
        }
    }
}
</script>

<style>

</style>

import api from '../../api';
const state = {
    contactObj: {},
}
const getters = {

    getContactList (state) {
        if (state.contactObj.data && state.contactObj.data.length) {
            return state.contactObj.data
        }
        return []
    },
    getContactPagination (state) {
        if (state.contactObj.last_page) {
            return {
                page_count: state.contactObj.last_page,
                total: state.contactObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_CONTACT_OBJ (state, payload) {
        state.contactObj = payload;
    }
}
const actions = {
    async getContact({ commit }, data = {}) {
        const resp = await api.contact.getContact(data);
        commit('SET_CONTACT_OBJ', resp.data);
    },
    async updateContact ({ commit }, data = {}) {
        const resp = await api.contact.updateContact(data);
        return resp.data
    },
    async createContact ({ commit }, data = {}) {
        const resp = await api.contact.createContact(data);
        return resp.data
    },
    async destroyContact ({ commit }, data = {}) {
        const resp = await api.contact.destroyContact(data);
        return resp.data
    },

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

<template>
    <v-dialog
        max-width="900px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create Permision
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateRoleAndPermision">
                    <v-text-field label="Name" v-model="name" />
                    <v-text-field label="Description" v-model="description" />
                    <v-text-field label="Permision" v-model="controller" />
                    <v-autocomplete
                        :items="permisions"
                        v-model="parent_id"
                        label="Group"
                        item-text="description"
                        item-value="id"
                    >
                </v-autocomplete>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" type="submit" block>Save</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="warning" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: ['openCreateDialog', 'permisions'],
    data () {
        return {
            dialog: false,
            name: '',
            description: '',
            controller: null,
            parent_id: null
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.name = '';
                this.description = '';
            }
        }
    },
    methods: {
        ...mapActions('roleAndPermision', ['createPermision']),
        handleCreateRoleAndPermision() {
            if (!this.name || this.name.trim() === '' ||  this.name.length < 4) {
                alert('Role Name is required');
                return false;
            }
            const data = {
                name: this.name,
                description: this.description,
                controller: this.controller,
                parent_id: this.parent_id ? this.parent_id : 0
            }

            this.createPermision(data).then(resp => {
                console.log(resp)
                this.$emit('close-create-dialog', true);
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style>

</style>

<template>
     <v-row>
        <v-col cols="12">
            <v-toolbar>
                <v-btn class="mr-4" @click="$router.push('/soi-keo')"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-toolbar-title>Create Sòi Kèo</v-toolbar-title>
            </v-toolbar>
        </v-col>
        <v-col cols="8">
           <v-card elevation="2">
                <v-card-text>
                    <v-text-field label="Title" v-model="title"></v-text-field>
                    <Ckeditor v-model="content" />
                    <div class="my-4"></div>
                    <v-row>
                        <v-col cols="6">
                            <div style="width: 100%; text-align: center; cursor-pointer">
                                <v-avatar size="80" @click="handleOpenMedia('home')">
                                    <template v-if="home_flag">
                                        <img :src="home_flag" alt="">
                                    </template>
                                    <template v-else>
                                         <div style="font-weight: 700; font-size: 21px; width: 70px; height: 70px; border-radius: 100%; border: 1px solid yellow; display: flex; align-items:center; justify-content:center;">
                                            Logo
                                        </div>
                                    </template>

                                </v-avatar>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="width: 100%; text-align: center;cursor-pointer">
                               <v-avatar size="80" @click="handleOpenMedia('away')">
                                     <template v-if="away_flag">
                                        <img :src="away_flag" alt="">
                                    </template>
                                    <template v-else>
                                         <div style="font-weight: 700; font-size: 21px; width: 70px; height: 70px; border-radius: 100%; border: 1px solid yellow; display: flex; align-items:center; justify-content:center;">
                                            Logo
                                        </div>
                                    </template>
                                </v-avatar>
                            </div>

                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Home name" v-model="home_name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Away name" v-model="away_name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo chấp" v-model="handicap"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo tài xỉu" v-model="overunder"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo 1x2" v-model="euro"></v-text-field>
                        </v-col>
                         <v-col cols="6">
                            <v-text-field label="Chọn kèo" v-model="choose_odd"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo thắng / thua ( 1: Thắng | 2 : Hòa | 3: Thua)" v-model="odd_status" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Cược ngay link" v-model="bet_now"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Bảng đấu" v-model="league_table"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Sân vận động (không bắt buộc)" v-model="stadium"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
           </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="mb-4">
                <v-card-title>
                    Thời gian trận đấu
                </v-card-title>
                <v-card-text>
                    <v-datetime-picker label="Chọn thời gian" v-model="match_time" datetime="Date"> </v-datetime-picker>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Trạng thái
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-switch v-model="hot" label="Trận hot"></v-switch>
                        </v-col>
                    </v-row>

                    <v-radio-group v-model="status">
                        <v-row>
                            <v-col>
                                <v-radio label="Publish" :value="1"></v-radio>
                            </v-col>
                            <v-col>
                                <v-radio label="Private" :value="0"></v-radio>
                            </v-col>
                        </v-row>
                    </v-radio-group>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Giải đấu
                </v-card-title>
                <v-card-text>
                    <v-select label="Chọn giải đấu" :items="leagues" item-text="name" item-value="id" v-model="league_id"></v-select>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Ảnh bài viết
                </v-card-title>
                <v-card-text>
                    <div class="feature-img" style="width: 100%; height: 200px; background: gray; cursor: pointer;" @click="handleOpenMedia('feature')">
                        <img v-if="feature_image" :src="feature_image" alt="" style="width: 100%; height: 100%;">
                    </div>
                </v-card-text>
            </v-card>

            <v-row>
                <v-col>
                    <v-btn block color="primary" @click="handleSaveSoikeo">Save soi kèo</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
     </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Ckeditor from '../components/Ckeditor.vue';
import Media from '../components/Media.vue';
export default {
    components: {
        Ckeditor,
        Media
    },
    data () {
        return {
            title: '',
            content: '',
            feature_image: '',
            handicap: '',
            overunder: '',
            euro: '',
            league_id: '',
            match_time: '',
            stadium: '',
            home_name: '',
            away_name: '',
            home_flag: '',
            away_flag: '',
            hot: 0,
            openMedia: false,
            selectMedia: '',
            status: 1,
            choose_odd: '',
            odd_status: 0,
            league_table: '',
            bet_now: ''
        }
    },
    computed: {
        ...mapGetters('league', {
            leagues: 'getLeagueList'
        })
    },
    created () {
        this.initPageData();
        console.log(32525)
    },
    methods: {
        ...mapActions('predict', ['createPredict']),
        ...mapActions('league', ['getLeagueList']),
        async initPageData () {
            try {

                await this.getLeagueList({ per_page: 10000 })
            } catch (err) {
                console.log(err)
            }
        },
        handleOpenMedia (name) {
            this.selectMedia = name
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                if (this.selectMedia === 'home') {

                    this.home_flag = item.image_url;
                }
                else if (this.selectMedia === 'away') {
                    this.away_flag = item.image_url;
                }
                else if (this.selectMedia === 'feature') {
                    this.feature_image = item.image_url;
                }
            }

        },
        async handleSaveSoikeo () {


            try {

                if (!this.title || !this.content || !this.feature_image || !this.league_id || !this.match_time || !this.home_name || !this.away_name) {
                    alert('Vui lòng điền đầy đủ thông tin');
                    return;
                }

                const data = {
                    title: this.title,
                    content: this.content,
                    home_flag: this.home_flag,
                    away_flag: this.away_flag,
                    home_name: this.home_name,
                    away_name: this.away_name,
                    handicap: this.handicap,
                    overunder: this.overunder,
                    euro: this.euro,
                    stadium: this.stadium,
                    match_time: this.formatDateTime(this.match_time),
                    status: this.status,
                    hot: this.hot,
                    league_id: this.league_id,
                    feature_image: this.feature_image,
                    status: this.status,
                    choose_odd: this.choose_odd,
                    odd_status: this.odd_status,
                    bet_now: this.bet_now,
                    league_table: this.league_table
                }

                const resp = await this.createPredict(data);

                this.$router.push('/soi-keo');

            } catch (err) {
                alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại');
                console.log(err)
            }

        },
        formatDateTime (datetime) {
             const date = new Date(datetime);
            return (date.getFullYear() + '-' + ((date.getMonth() + 1).toString()).padStart(2, '0') + '-' + date.getDate().toString()).padStart(2, '0') + ' ' + (date.getHours().toString()).padStart(2, '0') + ':' + (date.getMinutes().toString()).padStart(2, '0');
        }
    }
}
</script>

<style>

</style>

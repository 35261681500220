<template>
    <div class="pub-app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    components:{
    },
    computed: {

    },
    created() {

    },
    mounted () {

    },
    methods: {

    }
}
</script>

<style>

</style>

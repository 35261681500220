var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Contact")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSearchName},model:{value:(_vm.name_search),callback:function ($$v) {_vm.name_search=$$v},expression:"name_search"}}),_vm._v(" "),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.handleClearFilter}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.selected.length,"color":"pink"},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Contact")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.anchor_text",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.anchor_text},on:{"update:returnValue":function($event){return _vm.$set(item, "anchor_text", $event)},"update:return-value":function($event){return _vm.$set(item, "anchor_text", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'anchor_text')},"save":function($event){return _vm.handleSaveItem('anchor_text')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Anchor Text"},model:{value:(_vm.anchor_text),callback:function ($$v) {_vm.anchor_text=$$v},expression:"anchor_text"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.anchor_text))])],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.link},on:{"update:returnValue":function($event){return _vm.$set(item, "link", $event)},"update:return-value":function($event){return _vm.$set(item, "link", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'link')},"save":function($event){return _vm.handleSaveItem('link')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Link"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.link))])],1)]}},{key:"item.random_links",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.random_links},on:{"update:returnValue":function($event){return _vm.$set(item, "random_links", $event)},"update:return-value":function($event){return _vm.$set(item, "random_links", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'random_links')},"save":function($event){return _vm.handleSaveItem('random_links')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Random Links"},model:{value:(_vm.random_links),callback:function ($$v) {_vm.random_links=$$v},expression:"random_links"}})]},proxy:true}],null,true)},[_c('div',{staticStyle:{"max-width":"250px"}},[_c('v-list-item',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.random_links))])],1)])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.description},on:{"update:returnValue":function($event){return _vm.$set(item, "description", $event)},"update:return-value":function($event){return _vm.$set(item, "description", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'description')},"save":function($event){return _vm.handleSaveItem('description')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.description))])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.type},on:{"update:returnValue":function($event){return _vm.$set(item, "type", $event)},"update:return-value":function($event){return _vm.$set(item, "type", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'type')},"save":function($event){return _vm.handleSaveItem('type')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-autocomplete',{attrs:{"items":[{ id: 1, label: 'Fixed'}, { id: 0, label: 'Random' }],"label":"Type","item-text":"label","item-value":"id"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.type === 1 ? 'Fixed' : 'Random'))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-switch',{attrs:{"input-value":item.status},on:{"click":function($event){return _vm.handleChangeTypeStatus(item, 'status')}}})],1)]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.priority},on:{"update:returnValue":function($event){return _vm.$set(item, "priority", $event)},"update:return-value":function($event){return _vm.$set(item, "priority", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'priority')},"save":function($event){return _vm.handleSaveItem('priority')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Order"},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.priority))])],1)]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","size":"36"},on:{"click":function($event){return _vm.handleOpenMedia(item)}}},[(item.icon)?_c('img',{attrs:{"src":item.icon,"alt":""}}):_c('span',{staticClass:"white--text text-h6 text-uppercase"},[_vm._v(_vm._s(item.name.substring(0,2)))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){return _vm.handleDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),(_vm.items.length)?_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n                Total: "+_vm._s(_vm.pagination.total)+"\n            ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('CreateContactDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('Media',{attrs:{"openMedia":_vm.openMedia},on:{"close-media":_vm.handleCloseMedia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
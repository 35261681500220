import api from '../../api';
const state = {
    predictObj: {},
}
const getters = {

    getPredictList (state) {
        if (state.predictObj.data && state.predictObj.data.length) {
            return state.predictObj.data
        }
        return []
    },
    getPredictPagination (state) {
        if (state.predictObj.last_page) {
            return {
                page_count: state.predictObj.last_page,
                total: state.predictObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_PREDICT_OBJ (state, payload) {
        state.predictObj = payload;
    }
}
const actions = {
    async getPredictList({ commit }, data = {}) {
        const resp = await api.predict.getPredictList(data);
        commit('SET_PREDICT_OBJ', resp.data);
    },
    async getPredictDetail({ commit }, data = {}) {
        const resp = await api.predict.getPredictDetail(data);
        return resp.data
    },
    async updatePredict ({ commit }, data = {}) {
        const resp = await api.predict.updatePredict(data);
        return resp.data
    },
    async createPredict ({ commit }, data = {}) {
        const resp = await api.predict.createPredict(data);
        return resp.data
    },
    async destroyPredict ({ commit }, data = {}) {
        const resp = await api.predict.destroyPredict(data);
        return resp.data
    },
    async getPredictMatchInfo({ commit }, data = {}) {
        const resp = await api.predict.getPredictMatchInfo(data);
        return resp.data
    },


}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

const baseUrl = '/api/contact';
export default axios => ({
    getContact (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateContact(data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createContact(data) {
        return axios.post(baseUrl, data);
    },
    destroyContact(data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    }
});

import api from '../../api';
const state = {
    contactDomainObj: {},
}
const getters = {

    getContactDomainList (state) {
        if (state.contactDomainObj.data && state.contactDomainObj.data.length) {
            return state.contactDomainObj.data
        }
        return []
    },
    getContactDomainPagination (state) {
        if (state.contactDomainObj.last_page) {
            return {
                page_count: state.contactDomainObj.last_page,
                total: state.contactDomainObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_CONTACT_DOMAIN_OBJ (state, payload) {
        state.contactDomainObj = payload;
    }
}
const actions = {
    async getContactDomain({ commit }, data = {}) {
        const resp = await api.contactDomain.getContactDomain(data);
        commit('SET_CONTACT_DOMAIN_OBJ', resp.data);
    },
    async updateContactDomain({ commit }, data = {}) {
        const resp = await api.contactDomain.updateContactDomain(data);
        return resp.data
    },
    async createContactDomain ({ commit }, data = {}) {
        const resp = await api.contactDomain.createContactDomain(data);
        return resp.data
    },
    async destroyContactDomain ({ commit }, data = {}) {
        const resp = await api.contactDomain.destroyContactDomain(data);
        return resp.data
    },
    async updateAllContactDomainContent({ commit }, data = {}) {
        const resp = await api.contactDomain.updateAllContactDomainContent(data);
        return resp.data
    },

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

import api from '../../api';
const state = {
    countDownObj: {},
}
const getters = {

    getCountDownList (state) {
        if (state.countDownObj.data && state.countDownObj.data.length) {
            return state.countDownObj.data
        }
        return []
    },
    getCountDownPagination (state) {
        if (state.countDownObj.last_page) {
            return {
                page_count: state.countDownObj.last_page,
                total: state.countDownObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_COUNTDOWN_OBJ (state, payload) {
        state.countDownObj = payload;
    }
}
const actions = {
    async getCountDown({ commit }, data = {}) {
        const resp = await api.countDown.getCountDown(data);
        commit('SET_COUNTDOWN_OBJ', resp.data);
    },
    async updateCountDown ({ commit }, data = {}) {
        const resp = await api.countDown.updateCountDown(data);
        return resp.data
    },
    async createCountDown ({ commit }, data = {}) {
        const resp = await api.countDown.createCountDown(data);
        return resp.data
    },
    async createManyCountDown ({ commit }, data = {}) {
        const resp = await api.countDown.createManyCountDown(data);
        return resp.data
    },
    async destroyCountDown ({ commit }, data = {}) {
        const resp = await api.countDown.destroyCountDown(data);
        return resp.data
    },

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

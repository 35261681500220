const baseUrl = '/api/contact-domain';
export default axios => ({
    getContactDomain (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateContactDomain(data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createContactDomain(data) {
        return axios.post(baseUrl, data);
    },
    destroyContactDomain(data = {}) {
        return axios.post(baseUrl + '/destroy', data);
    },
    updateAllContactDomainContent (data = {}) {
        return axios.post(baseUrl + '/update-all-domain-content', data);
    }
});

<template>
    <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                        <v-toolbar-title>Contact</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-text-field
                            v-model="name_search"
                            label="Search"
                            outlined
                            dense
                            hide-details=""
                            append-icon="mdi-feature-search-outline"
                            @click:append="handleSearchName"
                            class="ma-1"
                        >
                        </v-text-field>
                        <v-btn color="red" @click="handleClearFilter"><v-icon>mdi-close</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <!-- <v-btn :disabled="!selected.length" color="primary" @click="handleClonePost" class="mr-2"><v-icon>mdi-content-copy</v-icon> Clone</v-btn> -->
                        <v-btn :disabled="!selected.length" color="pink" @click="handleDeleteMany" class="mr-2">Delete</v-btn>
                        <v-btn color="primary" @click="openCreateDialog = true"><v-icon>mdi-plus</v-icon>Create Contact</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.name`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.id"
                        @open="handelOpenEditItem(item, 'name')"
                        @save="handleSaveItem('name')"
                    >
                        <v-list-item>{{ item.name }}</v-list-item>
                        <template #input>
                            <v-text-field label="Name" v-model="name"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.anchor_text`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.anchor_text"
                        @open="handelOpenEditItem(item, 'anchor_text')"
                        @save="handleSaveItem('anchor_text')"
                    >
                        <v-list-item>{{ item.anchor_text }}</v-list-item>
                        <template #input>
                            <v-text-field label="Anchor Text" v-model="anchor_text"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.link`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.link"
                        @open="handelOpenEditItem(item, 'link')"
                        @save="handleSaveItem('link')"
                    >
                        <v-list-item>{{ item.link }}</v-list-item>
                        <template #input>
                            <v-text-field label="Link" v-model="link"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.random_links`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.random_links"
                        @open="handelOpenEditItem(item, 'random_links')"
                        @save="handleSaveItem('random_links')"
                    >
                        <div style="max-width: 250px;">
                            <v-list-item class="text-truncate">{{ item.random_links }}</v-list-item>
                        </div>
                        <template #input>
                            <v-text-field label="Random Links" v-model="random_links"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.description`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.description"
                        @open="handelOpenEditItem(item, 'description')"
                        @save="handleSaveItem('description')"
                    >
                        <v-list-item>{{ item.description }}</v-list-item>
                        <template #input>
                            <v-text-field label="Description" v-model="description"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.type`]="{item}">
                    <v-edit-dialog :return-value.sync="item.type" @open="handelOpenEditItem(item, 'type')" @save="handleSaveItem('type')">
                        <v-list-item>{{ item.type === 1 ? 'Fixed' : 'Random' }}</v-list-item>
                        <template #input>
                            <v-autocomplete
                                v-model="type"
                                :items="[{ id: 1, label: 'Fixed'}, { id: 0, label: 'Random' }]"
                                label="Type"
                                item-text="label"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.status`]="{item}">
                    <div class="d-flex align-center justify-center">
                        <v-switch :input-value="item.status" @click="handleChangeTypeStatus(item, 'status')" />
                    </div>
                </template>
                <template #[`item.priority`]="{item}">
                     <v-edit-dialog
                        :return-value.sync="item.priority"
                        @open="handelOpenEditItem(item, 'priority')"
                        @save="handleSaveItem('priority')"
                    >
                        <v-list-item>{{ item.priority }}</v-list-item>
                        <template #input>
                            <v-text-field type="number" label="Order" v-model="priority"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.icon`]="{item}">
                    <v-avatar color="primary" size="36" @click="handleOpenMedia(item)" style="cursor: pointer;">
                        <img v-if="item.icon" :src="item.icon" alt="">
                        <span v-else class="white--text text-h6 text-uppercase">{{ item.name.substring(0,2) }}</span>
                    </v-avatar>
                </template>
                 <template #[`item.action`]="{item}">
                    <v-btn color="red" text small @click="handleDeleteItem(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
            <div v-if="items.length" class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="current_page" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
        <CreateContactDialog :openCreateDialog="openCreateDialog" @close-create-dialog="handleCloseCreateDialog" />
        <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateContactDialog from '../components/CreateContactDialog.vue';
import Media from '../components/Media.vue';
export default {
    components: {
        CreateContactDialog,
        Media
    },
    data () {
        return {
            headers: [
                // { text: 'ID', value: 'id', align: 'center', sortable: true,  width: '10%' },
                { text: 'Name', value: 'name', align: 'center'},
                { text: 'Icon', value: 'icon', align: 'center'},
                { text: 'Anchor Text', value: 'anchor_text', align: 'center'},
                { text: 'Description', value: 'description', align: 'center'},
                { text: 'Link Default', value: 'link', align: 'center'},
                { text: 'Link random', value: 'random_links', align: 'center'},
                { text: 'Type', value: 'type', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Order', value: 'priority', align: 'center'},
                { text: 'Action', value: 'action', align: 'center', sortable: false}
            ],
            name: '',
            anchor_text: '',
            description: '',
            link: '',
            random_links: '',
            priority: 0,
            type: '',
            status: '',
            page: 1,
            current_page: 1,
            openCreateDialog: false,
            selected: [],
            loading: false,
            name_search: '',
            openMedia: false
        }
    },
    computed: {
        ...mapGetters('contact', {
            items: 'getContactList',
            pagination: 'getContactPagination'
        })
    },
    created () {
        this.loadPageData();
    },
    methods: {
        ...mapActions('contact', ['getContact', 'updateContact', 'destroyContact', 'createContact']),
        async loadPageData () {
            try {
                this.loading = true;
                await this.getContact({
                    per_page: this.per_page,
                    page: this.current_page
                });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err)
            }
        },
        resetPageData () {
            this.name = '';
            this.description = '';
            this.anchor_text = '';
            this.link = '';
            this.random_links = '';
            this.type = '';
            this.status = '';
        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            this[index] = item[index];

        },
        handleSaveItem (index) {
            let update = {...this.editItem, [index]: this[index] };
            this.loading = true;
            this.updateContact(update)
                .then(resp => {
                    this.resetPageData();
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.resetPageData();
                });
        },
        handleDeleteItem (id) {
            const deleteConfirm = confirm('are you sure to delete ?');

            if (deleteConfirm) {
                this.destroyContact({ ids: [id] })
                    .then(resp => {
                        this.loadPageData();
                    })
                    .catch (err => {
                        console.log(err);
                    })
            }
        },
        handleDeleteMany () {
            if (this.selected.length) {
                const deleteConfirm = confirm('are you sure to delete ?');
                const ids = this.selected.map(item => item.id);
                if (deleteConfirm) {
                    this.destroyContact({ ids })
                        .then(resp => {
                            this.loadPageData();
                        })
                        .catch (err => {
                            console.log(err);
                        })
                }
            }
        },
        handleCloseCreateDialog (update) {
            this.openCreateDialog = false;
            if (update) this.loadPageData();
        },
        handleNewPage (currentPage) {
            this.current_page = currentPage;
            this.loadPageData();
        },
        // handleClonePost () {
        //     if (!this.selected.length) {
        //         alert('Pls select at least 1 row to clone');
        //         return;
        //     }

        //     this.createManySatiliteDomain(this.selected)
        //         .then(resp => {
        //             this.selected = [];
        //             this.loadPageData();
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         })
        // },
        handleClearFilter () {
            this.name_search = '';
            this.loadPageData();
        },
        handleSearchName () {
            if(this.name_search === '') alert('Pls enter keywords to search...');
            this.loadPageData();
        },
        handleOpenMedia (item) {
            this.editItem = item;
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.icon = item.image_url;
                this.handleSaveItem('icon');
            }

        },
        handleChangeTypeStatus (item, key) {
            const data = { ...item, [key]: item[key] ? 0 : 1 };
            this.loading = true;
            this.updateContact(data)
                .then(resp => {
                    this.loadPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err)
                });
        },
    }
}
</script>

<style scoped>
</style>

<template>
    <v-dialog
        max-width="900px"
        v-model="dialog"
        persistent
    >
        <v-card>
            <v-card-title>
                Create Contact
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="handleCreateTeleSaleLine">
                    <v-row>

                        <v-col cols="12">
                            <v-text-field label="Name" v-model="name" />
                        </v-col>
                        <v-col cols="12">
                            <div class="logo" style="margin-bottom: 20px;">Icon</div>
                            <v-avatar color="primary" size="36" @click="handleOpenMedia()" style="cursor: pointer;">
                                <img v-if="icon" :src="icon" alt="">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Anchor Text" v-model="anchor_text" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Description" v-model="description" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Link" v-model="link" />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea label="Random Link (Each Link seperate by ,)" v-model="random_links" />
                        </v-col>
                        <v-col cols="12">
                            <div class="">Type</div>
                            <v-radio-group
                                v-model="type"
                                row
                            >
                                <v-radio
                                    label="Fix"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    label="Random"
                                    :value="0"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <div class="">Status</div>
                            <v-radio-group
                                v-model="status"
                                row
                            >
                                <v-radio
                                    label="Active"
                                    :value="1"
                                ></v-radio>
                                <v-radio
                                    label="Inactive"
                                    :value="0"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                            <v-btn block color="warning" type="submit">Save</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="pink" block @click="$emit('close-create-dialog')">Cancel</v-btn>
                        </v-col>
                    </v-row>
                    <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Media from '../components/Media.vue';
export default {
    props: ['openCreateDialog'],
    components: {
        Media
    },
    data () {
        return {
            name: '',
            description: '',
            icon: '',
            anchor_text: '',
            link: '',
            random_links: '',
            type: 1,
            status: 1,
            dialog: false,
            openMedia: false
        }
    },
    watch: {
        openCreateDialog (val) {
            if (val) {
                this.dialog = true;
            } else {
                this.dialog = false;
                this.name = '';
                this.description = '';
                this.icon = '';
                this.anchor_text = '';
                this.link = '';
                this.random_links = '';
                this.type = '';
                this.status = '';
            }
        }
    },
    methods: {
        ...mapActions('contact', ['createContact']),
        handleCreateTeleSaleLine () {
            if (this.name === '' || this.name.trim() === '' || this.anchor_text === '' || this.link === '' || this.random_links === '') {
                alert('Name, Anchortext, Link, Random Links are requied');
                return false;
            }

            const data = {
                name: this.name,
                description: this.description,
                icon: this.icon,
                link: this.link,
                random_links: this.random_links,
                type: this.type,
                status: this.status,
                anchor_text: this.anchor_text,
            }

            this.createContact(data)
                .then(resp => {
                    this.$emit('close-create-dialog', true);
                })
                .catch (err => {
                    alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'err');
                })
        },
        handleOpenMedia () {
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.icon = item.image_url;
            }

        },
    }
}
</script>

<style>

</style>

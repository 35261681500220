<template>
   <div class="getcode">
        <button type="button" class="bubbly-button" @click="handleGetCode()">{{ number && run ? 'Vui lòng chờ ' + number + ' s' : password ? password : text }}</button>
   </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {

    data () {
        return {
            text: 'Lấy mã ngay',
            run: false,
            number: 45,
            password: null,
            keyword: null,
            host: null
        }
    },
    watch: {
        number (value) {
            if (value === 0) {
                this.getPassword();
            }
        }
    },
    computed: {

    },
    created () {
        this.keyword = this.$route.query.keyword;
        this.host = this.$route.query.host
    },
    methods: {
        handleGetCode() {
            if (this.run === true) return;

            let interval = setInterval(() => {
                if (this.number <= 0 ) {
                    clearInterval(interval);
                    this.run = false;
                }
                else {
                    this.run = true;
                    this.number--;
                }
            }, 1000)

        },
        async getPassword () {
            try {
                const resp = await axios.post('https://link.cado.pro/api/keyword/password', { name: this.keyword, host: this.host });
                this.password = resp.data
            } catch (err) {
                console.log(err)
            }
        }
    }
}
</script>

<style scoped>
.getcode {
    width: 100%;
    padding: 10px;
    margin: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bubbly-button {
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffb701;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  /* transition: transform ease-in 0.1s, box-shadow ease-in 0.25s; */
  /* box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5); */
  user-select: text;
}
</style>

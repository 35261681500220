var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listRole,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Role & Permision")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Role")],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.openCreatePermisionDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create Permision")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name ? item.name : 'No Name'))])],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'description')},"save":function($event){return _vm.handleSaveItem('description')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.description ? item.description : 'No Description'))])],1)]}},{key:"item.permisions",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'permisions')},"save":function($event){return _vm.handleSaveItem('permision_ids')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-card',{attrs:{"max-width":"600px","elevation":"0"}},[_c('v-card-title',[_vm._v("\n                                Edit Permision\n                            ")]),_vm._v(" "),_c('v-card-text',{staticClass:"fix-height mb-4",staticStyle:{"max-height":"600px"}},_vm._l((_vm.permisions),function(permision){return _c('v-card',{key:permision.id,staticClass:"mb-2",attrs:{"color":"#313131"}},[(permision.permision_children && permision.permision_children.length)?[_c('v-card-title',[_c('v-checkbox',{attrs:{"label":permision.description,"color":"red","value":1,"hide-details":""},on:{"change":function($event){return _vm.handleSelectAllPermision($event, permision)}}})],1),_vm._v(" "),_c('v-divider',{attrs:{"horizontal":""}}),_vm._v(" "),_c('v-card-text',[_c('v-row',_vm._l((permision.permision_children),function(per){return _c('v-col',{key:per.id,attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":per.name,"color":"red","value":per.id,"hide-details":""},model:{value:(_vm.permision_ids),callback:function ($$v) {_vm.permision_ids=$$v},expression:"permision_ids"}})],1)}),1)],1)]:_vm._e()],2)}),1)],1)]},proxy:true}],null,true)},[(item.permisions && item.permisions.length)?_c('div',_vm._l((item.permisions),function(permision){return _c('v-chip',{key:permision.id,staticClass:"ma-2",attrs:{"small":""}},[_vm._v("\n                            "+_vm._s(permision.name)+"\n                        ")])}),1):_vm._e()])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.handleDeleteRole(item.id)}}},[_vm._v("Delete")])]}}],null,true)})],1),_vm._v(" "),_c('CreateRoleAndPermisionDialog',{attrs:{"permisions":_vm.permisions,"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('CreatePermisionDialog',{attrs:{"permisions":_vm.permisions,"openCreateDialog":_vm.openCreatePermisionDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
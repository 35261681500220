import api from '../../api';
const state = {
    leagueObj: {},
}
const getters = {

    getLeagueList (state) {
        if (state.leagueObj.data && state.leagueObj.data.length) {
            return state.leagueObj.data
        }
        return []
    },
    getLeaguePagination (state) {
        if (state.leagueObj.last_page) {
            return {
                page_count: state.leagueObj.last_page,
                total: state.leagueObj.total,
            }
        }
        return {}
    },
}
const mutations = {
    SET_LEAGUE_OBJ (state, payload) {
        state.leagueObj = payload;
    }
}
const actions = {
    async getLeagueList({ commit }, data = {}) {
        const resp = await api.league.getLeagueList(data);
        commit('SET_LEAGUE_OBJ', resp.data);
    },
    async updateLeague ({ commit }, data = {}) {
        const resp = await api.league.updateLeague(data);
        return resp.data
    },
    async createLeague ({ commit }, data = {}) {
        const resp = await api.league.createLeague(data);
        return resp.data
    },
    async destroyLeague ({ commit }, data = {}) {
        const resp = await api.league.destroyLeague(data);
        return resp.data
    },

}
export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
}

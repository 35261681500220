<template>
     <v-row>
        <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="topLists"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
                show-expand
                v-model="selected"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                    <v-toolbar-title>Top List</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div style="width: 100px; margin-right: 1rem;">
                        <v-select
                            :items="['name', 'domain']"
                            v-model="searchType"
                            hide-details=""
                            dense
                        ></v-select>
                    </div>
                    <v-text-field
                            v-model="searchText"
                            label="Search"
                            outlined
                            dense
                            hide-details=""
                            append-icon="mdi-feature-search-outline"
                            @click:append="handleSearch"
                            class="ma-1"
                        >
                    </v-text-field>
                    <div style="width: 100px; margin-left: 1rem;">
                        <v-select
                            :items="[{ id: 1, name: 'Active' }, { id: 0, name: 'Inactive' }]"
                            item-text="name"
                            item-value="id"
                            v-model="statusFilter"
                            @change="handleStatusChange"
                            hide-details=""
                            dense
                            label="Status"
                        ></v-select>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="mr-2" @click="handleDeleteMany" :disabled="!selected.length">Delete</v-btn>
                    <v-btn color="primary" @click="openCreateDialog = true"><v-icon>mdi-plus</v-icon>Create New</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.name`]="{item}">
                    <v-edit-dialog
                        :return-value.sync="item.id"
                        @open="handelOpenEditItem(item, 'name')"
                        @save="handleSaveItem('name')"
                    >
                        <v-list-item>{{ item.name }}</v-list-item>
                        <template #input>
                            <v-text-field label="Name" v-model="name"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.domain`]="{item}">
                    <v-edit-dialog
                        :return-value.sync="item.domain"
                        @open="handelOpenEditItem(item, 'domain')"
                        @save="handleSaveItem('domain')"
                    >
                        <v-list-item>{{ item.domain }}</v-list-item>
                        <template #input>
                            <v-text-field label="Domain" v-model="domain"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.status`]="{item}">
                    <v-edit-dialog :return-value.sync="item.status" @open="handelOpenEditItem(item, 'status')" @save="handleSaveItem('status')">
                        <v-btn small :color="item.status === 1 ? 'primary' : 'red'">{{ item.status === 1 ? 'Active' : 'Inactive' }}</v-btn>
                        <template #input>
                            <v-autocomplete
                                v-model="status"
                                :items="[{ id: 1, name: 'Active' }, { id: 0, name: 'Inactive' }]"
                                label="Status"
                                item-text="name"
                                item-value="id"
                            >
                            </v-autocomplete>
                        </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <TopListItem :listItems="item.items" :topId="item.id" @save-toplist="handleSaveTopList"  @create-toplist="handleCreateTopList" @destroy-toplist="handleDestroyTopList" :saveLoading="loading" />
                    </td>
                </template>
                <template #[`item.action`]="{item}">
                    <v-btn color="primary" small class="ma-1" @click="handleCopyShortCode(item.id)"><v-icon left>mdi-code-brackets</v-icon>Copy ShortCode</v-btn>
                    <v-btn color="pink" small class="ma-1" @click="handleCloneTopList(item)"><v-icon left>mdi-content-duplicate</v-icon>Clone</v-btn>
                    <v-btn color="red" small class="ma-1" @click="handleDeleteItem([item.id])"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
            <div class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="currentPage" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
        <CreateTopListDialog :openCreateDialog="openCreateDialog" @close-create-dialog="handleCloseCreateDialog" />
     </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateTopListDialog from '../components/CreateTopListDialog';
import TopListItem from '../components/TopListItem';
export default {
    components: {
        TopListItem,
        CreateTopListDialog
    },
    data () {
        return {
            headers: [
                // { text: 'ID', value: 'id', align: 'center'},
                { text: 'Name', value: 'name', align: 'center', width: '15%'},
                { text: 'Status', value: 'status', align: 'center'},

                { text: 'Domain', value: 'domain', align: 'center'},
                // { text: 'Content', value: 'content', align: 'center'},
                { text: 'Action', value: 'action', align: 'center'}
            ],
            name: null,
            status: null,
            loading: false,
            selected: [],
            openCreateDialog: false,
            domain: null,
            searchType: 'domain',
            searchText: null,
            statusFilter: null,
            // expanded: []

        }
    },
    computed: {
        ...mapGetters('topList', {
            topLists: 'getTopList',
            pagination: 'getTopListPaination'
        })
    },
    created () {
        this.loadPageData()
    },
    methods: {
        ...mapActions('topList', ['getTopList', 'updateTopList', 'destroyTopList', 'createTopList', 'saveTopListItem', 'createTopListItem', 'destroyTopListItem']),
        async loadPageData () {
            this.loading = true;
            try {
                await this.getTopList({
                    name: this.name,
                    status: this.status,
                    page: this.currentPage,
                    per_page: this.per_page,
                });
                this.loading = false;
            } catch (err) {
                console.log(err);
                this.loading = false;
            }
        },
        async handleSaveTopList (data) {
            try {
                this.loading = true;
                await this.saveTopListItem(data);
                await this.resetPageData();
                this.loading = false;
                // this.expanded = [];
            } catch (err) {
                this.loading = false
                console.log(err)
            }
        },
        handleCopyShortCode (id) {
            const text = '[toplist id="' + id + '"]';
            navigator.clipboard.writeText(text);
            alert('copy shortcode success');
        },
        async handleCloneTopList (item) {
            try {
                await this.createTopList({ name: item.name, content: item.content, id: item.id });
                await this.resetPageData();
            } catch (err) {
                console.log(err);
            }
        },
        handleSearchByField () {
            if (this.fieldVal) {
                this[this.fieldName] = this.fieldVal;
                this.loadPageData();
            }
        },
        async handleDeleteItem (ids) {
            try {
                const deleteConfirm = confirm('Are you sure want to delete this item');
                 if (deleteConfirm) {
                    this.loading = true;
                    await this.destroyTopList({ ids });
                    await this.resetPageData();
                    this.loading = false;
                 }
            } catch (err) {
                this.loading = false;
                console.log(err)
            }

        },
        async handleDeleteMany () {
             try {
                const deleteConfirm = confirm('Are you sure want to delete this item');
                 if (deleteConfirm) {
                    const ids = this.selected.map(item => item.id);
                    this.loading = true;
                    await this.destroyTopList({ ids });
                    await this.resetPageData();
                    this.loading = false;
                 }
            } catch (err) {
                this.loading = false;
                console.log(err)
            }

        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            if (index === 'line_id') {
                this.line_id = item.line.id
            } else {
                this[index] = item[index];
            }

        },
        handleSaveItem (index) {
            let update = { id: this.editItem.id, [index]: this[index] };
            this.loading = true;
            this.updateTopList(update)
                .then(resp => {
                    this.resetPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        resetPageData () {
            this.name = null,
            this.selected =[];
            this.openCreateDialog = false;
            this.loadPageData();
        },
        handleCloseCreateDialog (update) {
            this.openCreateDialog = false;
            if (update) this.loadPageData();
        },
        handleNewPage (currentPage) {
            this.currentPage = currentPage;
            this.loadPageData();
        },
        async handleCreateTopList (data) {
            try {
                this.loading = true;
                await this.createTopListItem(data);
                await this.resetPageData();
                this.loading = false;
            } catch (err) {
                this.loading = false
                console.log(err)
            }
        },
        async handleDestroyTopList (data) {
            try {
                this.loading = true;
                await this.destroyTopListItem(data);
                await this.resetPageData();
                this.loading = false;
            } catch (err) {
                this.loading = false
                console.log(err)
            }
        },
        async handleSearch () {
            if (!this.searchText) return;
            this.loading = true;
            try {
                await this.getTopList({
                    name: this.name,
                    status: this.status,
                    page: this.currentPage,
                    per_page: this.per_page,
                    [this.searchType]: this.searchText
                });
                this.loading = false;
            } catch (err) {
                console.log(err);
                this.loading = false;
            }
        },
         async handleStatusChange () {
            this.loading = true;
            try {
                await this.getTopList({
                    name: this.name,
                    status: this.status,
                    page: this.currentPage,
                    per_page: this.per_page,
                    [this.searchType]: this.searchText,
                    status: this.statusFilter
                });
                this.loading = false;
            } catch (err) {
                console.log(err);
                this.loading = false;
            }
        }
    }
}
</script>

<style>

</style>

<template>
     <v-row>
        <v-col cols="12">
            <v-toolbar>
                <v-btn class="mr-4" @click="$router.push('/soi-keo')"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-toolbar-title>Create Sòi Kèo</v-toolbar-title>
            </v-toolbar>
        </v-col>
        <v-col cols="8">
           <v-card elevation="2">
                <v-card-text>
                    <v-text-field label="Title" v-model="title"></v-text-field>
                    <Ckeditor v-model="content" />
                    <div class="my-4"></div>
                    <v-row>
                        <v-col cols="6">
                            <div style="width: 100%; text-align: center; cursor-pointer">
                                <v-avatar size="80" @click="handleOpenMedia('home')">
                                    <template v-if="home_flag">
                                        <img :src="home_flag" alt="">
                                    </template>
                                    <template v-else>
                                         <div style="font-weight: 700; font-size: 21px; width: 70px; height: 70px; border-radius: 100%; border: 1px solid yellow; display: flex; align-items:center; justify-content:center;">
                                            Logo
                                        </div>
                                    </template>

                                </v-avatar>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="width: 100%; text-align: center;cursor-pointer">
                               <v-avatar size="80" @click="handleOpenMedia('away')">
                                     <template v-if="away_flag">
                                        <img :src="away_flag" alt="">
                                    </template>
                                    <template v-else>
                                         <div style="font-weight: 700; font-size: 21px; width: 70px; height: 70px; border-radius: 100%; border: 1px solid yellow; display: flex; align-items:center; justify-content:center;">
                                            Logo
                                        </div>
                                    </template>
                                </v-avatar>
                            </div>

                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Home name" v-model="home_name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Away name" v-model="away_name"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo chấp" v-model="handicap"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo tài xỉu" v-model="overunder"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo 1x2" v-model="euro"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Chọn kèo" v-model="choose_odd"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Kèo thắng / thua ( 1: Thắng | 2 : Hòa | 3: Thua)" v-model="odd_status" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Cược ngay link" v-model="bet_now"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Bảng đấu" v-model="league_table"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Sân vận động (không bắt buộc)" v-model="stadium"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
           </v-card>
           <v-card elevation="2" class="mt-4">
                <v-card-text>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field label="Get link" v-model="get_link"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn color="primary" @click="handleGetMatchInfo()">Get Match Info</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
           </v-card>
           <v-card elevation="2" class="mt-4">
                <v-toolbar>
                    <v-tabs v-model="tab">
                        <v-tab :key="0">Đối đầu</v-tab>
                        <v-tab :key="1">Phong Độ</v-tab>
                        <v-tab :key="2">Lịch Thi đấu</v-tab>
                        <v-tab :key="3">BXH</v-tab>
                        <v-tab :key="4">MT Dự đoán</v-tab>
                    </v-tabs>
                </v-toolbar>
                <v-card>
                    <template v-if="tab === 0">
                        <v-card-text v-html="predict.htoh"></v-card-text>
                    </template>
                    <template v-if="tab === 1">
                        <v-card-text v-html="predict.performance"></v-card-text>
                    </template>
                    <template v-if="tab === 2">
                        <v-card-text v-html="predict.schedule"></v-card-text>
                    </template>
                    <template v-if="tab === 3">
                        <v-card-text v-html="predict.standing"></v-card-text>
                    </template>
                    <template v-if="tab === 4">
                        <v-card-text v-html="predict.predict"></v-card-text>
                    </template>
                </v-card>
           </v-card>
        </v-col>
        <v-col cols="4">
            <v-card class="mb-4">
                <v-card-title>
                    Thời gian trận đấu
                </v-card-title>
                <v-card-text>
                    <v-datetime-picker label="Chọn thời gian" v-model="match_time" datetime="Date"> </v-datetime-picker>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Trạng thái
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-switch v-model="hot" label="Trận hot"></v-switch>
                        </v-col>
                    </v-row>

                    <v-radio-group v-model="status">
                        <v-row>
                            <v-col>
                                <v-radio label="Publish" :value="1"></v-radio>
                            </v-col>
                            <v-col>
                                <v-radio label="Private" :value="0"></v-radio>
                            </v-col>
                        </v-row>
                    </v-radio-group>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Giải đấu
                </v-card-title>
                <v-card-text>
                    <v-select label="Chọn giải đấu" :items="leagues" item-text="name" item-value="id" v-model="league_id"></v-select>
                </v-card-text>
            </v-card>
            <v-card class="mb-4">
                <v-card-title>
                    Ảnh bài viết
                </v-card-title>
                <v-card-text>
                    <div class="feature-img" style="width: 100%; height: 200px; background: gray; cursor: pointer;" @click="handleOpenMedia('feature')">
                        <img v-if="feature_image" :src="feature_image" alt="" style="width: 100%; height: 100%;">
                    </div>
                </v-card-text>
            </v-card>

            <v-row>
                <v-col>
                    <v-btn block color="primary" @click="handleSaveSoikeo">Save soi kèo</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
     </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import predict from '../api/predict';
import Ckeditor from '../components/Ckeditor.vue';
import Media from '../components/Media.vue';
export default {
    components: {
        Ckeditor,
        Media
    },
    data () {
        return {
            id: null,
            title: '',
            content: '',
            feature_image: '',
            handicap: '',
            overunder: '',
            euro: '',
            league_id: '',
            match_time: '',
            stadium: '',
            home_name: '',
            away_name: '',
            home_flag: '',
            away_flag: '',
            hot: 0,
            openMedia: false,
            selectMedia: '',
            status: 1,
            choose_odd: '',
            odd_status: 0,
            league_table: '',
            bet_now: '',
            tab: null,
            predict: {},
            get_link: null
        }
    },
    computed: {
        ...mapGetters('league', {
            leagues: 'getLeagueList'
        })
    },
    created () {
        this.initPageData();
        console.log(this.$route.params.id);
    },
    methods: {
        ...mapActions('predict', ['updatePredict', 'getPredictDetail', 'getPredictMatchInfo']),
        ...mapActions('league', ['getLeagueList']),
        async initPageData () {
            try {
                const id = this.$route.params && this.$route.params.id ? this.$route.params.id : null
                const [leagues, predict] = await Promise.all([this.getLeagueList({ per_page: 10000 }), this.getPredictDetail(id)])
                this.predict = predict;
                this.id = predict.id;
                this.title = predict.title;
                this.content = predict.content;
                this.feature_image = predict.feature_image;
                this.handicap = predict.handicap;
                this.overunder = predict.overunder;
                this.euro = predict.euro;
                this.league_id = predict.league_id;
                this.match_time = new Date(predict.match_time);
                this.stadium = predict.stadium;
                this.home_name = predict.home_name;
                this.away_name = predict.away_name;
                this.home_flag = predict.home_flag;
                this.away_flag = predict.away_flag;
                this.hot = predict.hot;
                this.status = predict.status;
                this.choose_odd = predict.choose_odd;
                this.odd_status = predict.odd_status
                this.bet_now = predict.bet_now
                this.league_table = predict.league_table
                this.get_link = predict.get_link
            } catch (err) {
                console.log(err)
            }
        },
        handleOpenMedia (name) {
            this.selectMedia = name
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                if (this.selectMedia === 'home') {

                    this.home_flag = item.image_url;
                }
                else if (this.selectMedia === 'away') {
                    this.away_flag = item.image_url;
                }
                else if (this.selectMedia === 'feature') {
                    this.feature_image = item.image_url;
                }
            }

        },
        async handleSaveSoikeo () {
            try {

                if (!this.title || !this.content || !this.feature_image || !this.league_id || !this.match_time || !this.home_name || !this.away_name) {
                    alert('Vui lòng điền đầy đủ thông tin');
                    return;
                }

                const data = {
                    id: this.id,
                    title: this.title,
                    content: this.content,
                    home_flag: this.home_flag,
                    away_flag: this.away_flag,
                    home_name: this.home_name,
                    away_name: this.away_name,
                    handicap: this.handicap,
                    overunder: this.overunder,
                    euro: this.euro,
                    stadium: this.stadium,
                    match_time: this.formatDateTime(this.match_time),
                    status: this.status,
                    hot: this.hot,
                    league_id: this.league_id,
                    feature_image: this.feature_image,
                    status: this.status,
                    choose_odd: this.choose_odd,
                    odd_status: this.odd_status,
                    bet_now: this.bet_now,
                    league_table: this.league_table
                }

                const resp = await this.updatePredict(data);

                this.$router.push('/soi-keo');

            } catch (err) {
                alert(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại');
                console.log(err)
            }

        },
        formatDateTime (datetime) {
             const date = new Date(datetime);
            return (date.getFullYear() + '-' + ((date.getMonth() + 1).toString()).padStart(2, '0') + '-' + date.getDate().toString()).padStart(2, '0') + ' ' + (date.getHours().toString()).padStart(2, '0') + ':' + (date.getMinutes().toString()).padStart(2, '0');
        },
        async handleGetMatchInfo () {
            try {
                if (!this.get_link) {
                    alert('Pls enter the match link (look here https://bongda.wap.vn/du-doan-bong-da-cua-bao-chi.html)');
                    return;
                }
                this.loading = true;
                const resp = await this.getPredictMatchInfo({ url: this.get_link, id: this.predict.id });
                this.initPageData();
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log(err);
            }
        }
    }
}
</script>

<style>
.trd_phongdoweb_1 {
    width: 8%;
    text-align: center;
    color: #fff !important;
}
.trd_phongdoweb_2 {
    color: #fff !important;
    text-align: center;
}
.trd_phongdo2 {
    line-height: 24px;
    font-size: 11px;
    color: #fff;
    background-color: #c83233;
}
.trd_phongdo1 {
    line-height: 26px;
    font-size: 11px;
    color: #c83233;
    text-align: left;
    padding-left: 10px;
    background-color: #d5dcc7;
    border-top: 1px solid #b4bba8;
}
.trd_phongdo4 {
    background-color: #f7f3ea;
    line-height: 24px;
}
.trd_phongdoweb_3 {
    text-align: center;
    color: #464646;
}
.trd_phongdoweb_3a {
    text-align: right;
    padding-right: 5px;
    color: #464646;
}
.trd_phongdoweb_3b {
    text-align: left;
    padding-left: 5px;
    color: #464646;
}
.trd_phongdoweb_3c {
    text-align: center;
    font-size: 12px;
    color: #464646;
    background-color: #eee;
}
.trd_pdthongke {
    background-color: #fff;
    line-height: 22px;
    color: #595959;
    text-align: left;
    padding: 3px 0 22px 10px;
}

.form-icon {
    background: url(https://lh6.googleusercontent.com/-heBBMAEVJLc/UtON6cqU0sI/AAAAAAAAAQ0/EAGFe6a0vno/w16-h47-no/form-icons.png) no-repeat scroll 50% 50% transparent;
    float: right;
    height: 15px;
    margin: 2px 2px 0 0;
    opacity: 1;
    text-indent: -5000px;
    width: 16px;
}
.form-draw {
    background-position: 0 0;
}
.form-win {
    background-position: 0 -32px;
}
.camdam {
    color: #33c;
}
.LS_2_web {
    text-align: center;
    color: #464646;
    font-size: 11px;
}
.LS_4_web {
    text-align: right;
    padding-right: 3px;
    color: #464646;
    font-size: 11px;
}
.LS_5_web {
    text-align: left;
    padding-left: 3px;
    color: #464646;
    font-size: 11px;
    background-color: #eee;
}
.LS_5a_web {
    line-height: 20px;
    text-align: center;
    font-size: 13px;
    color: #5a5a5a;
}
.LS_6_web {
    text-align: left;
    padding-left: 3px;
    color: #464646;
    font-size: 11px;
}
.LS_7_web {
    text-align: center;
    color: #464646;
    font-size: 11px;
}
.trd-ttkhac2 {
    line-height: 22px;
    padding: 0 0 0 10px;
    background-color: #d5dcc7;
    color: #c83233;
    text-align: left;
    border-top: 1px solid #b4bba8;
}
.bxh_gd_1 {
    width: 7%;
    text-align: center;
    line-height: 20px;
    font-size: 11px;
}
.bxh_gd_2 {
    width: 37%;
    text-align: left;
    font-size: 11px;
}
tr:not(:first-child) td {
    color: #686868;
}
.bxh_bg_2doi {
    background-color: #ebe7dc;
}
.mm {
    margin: 5px 0;
    padding: 0 0 0 10px;
    line-height: 24px;
    text-align: left;
}
.mm .note {
    color: #686868;;
}
.xanhbxh {
    color: #007e8d;
}
</style>

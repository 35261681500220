<template>
     <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="leagues"
                :hide-default-footer="true"
                disable-pagination
                :loading="loading"
                show-select
                class="elevation-1"
            >
                <template #[`top`]>
                    <v-toolbar flat color="dark">
                    <v-toolbar-title>Leagues</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <div class="d-flex ma-1" style="max-width: 150px;">
                        <v-autocomplete
                            :items="fields"
                            v-model="fieldName"
                            item-text="name"
                            item-value="value"
                            label="Search Field"
                            hide-details=""
                            solo
                            outlined
                            dense
                            clear-icon="mdi-close"
                            clearable
                        >
                        </v-autocomplete>
                    </div>
                    <v-text-field
                        v-model="fieldVal"
                        label="Search"
                        outlined
                        dense
                        hide-details=""
                        append-icon="mdi-feature-search-outline"
                        @click:append="handleSearchByField"
                        class="ma-1"
                        :disabled="!fieldName"
                    >
                    </v-text-field>
                    <v-btn color="success" class="ma-1" @click="resetPageData"><v-icon>mdi-reload</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" class="mr-2" @click="handleDeleteMany" :disabled="!selected.length">Delete</v-btn>
                    <v-btn color="primary" @click="openCreateDialog = true"><v-icon>mdi-plus</v-icon>Create New</v-btn>
                    </v-toolbar>
                </template>
                <template #[`item.image`]="{item}">
                    <v-avatar color="primary" size="36" @click="handleOpenMedia(item)" style="cursor: pointer;">
                        <img v-if="item.image" :src="item.image" alt="">
                        <span v-else class="white--text text-h6 text-uppercase">{{ item.name.substring(0,2) }}</span>
                    </v-avatar>
                </template>
                <template #[`item.name`]="{item}">
                    <v-edit-dialog
                        :return-value.sync="item.id"
                        @open="handelOpenEditItem(item, 'name')"
                        @save="handleSaveItem('name')"
                    >
                        <v-list-item>{{ item.name }}</v-list-item>
                        <template #input>
                            <v-text-field label="Name" v-model="name"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.content`]="{item}">
                    <v-edit-dialog
                        :return-value.sync="item.id"
                        @open="handelOpenEditItem(item, 'content')"
                        @save="handleSaveItem('content')"
                    >
                        <v-list-item>{{ item.content }}</v-list-item>
                        <template #input>
                            <v-text-field label="Description" v-model="content"></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.shortcode`]="{item}">
                    <v-btn color="green" small @click="handleCopyLeagueShortCode(item.id)"><v-icon left>mdi-code-brackets</v-icon> Copy</v-btn>
                </template>
                 <template #[`item.action`]="{item}">
                    <v-btn color="red" text small @click="handleDeleteLeague(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
            <div class="text-center d-flex align-center justify-center">
                <v-btn text style="margin-top: 16px;">
                    Total: {{ pagination.total }}
                </v-btn>
                <v-pagination v-if="pagination.page_count > 1" v-model="currentPage" class="mt-4" :length="pagination.page_count" :total-visible="7" @input="handleNewPage" />
            </div>
        </v-col>
        <CreateLeagueDialog :openCreateDialog="openCreateDialog" @close-create-dialog="handleCloseCreateDialog" />
        <Media :openMedia="openMedia" @close-media="handleCloseMedia" />
     </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateLeagueDialog from '../components/CreateLeagueDialog';
import Media from '../components/Media.vue';
export default {
    components: {
        CreateLeagueDialog,
        Media
    },
    data () {
        return {
            headers: [
                { text: 'ID', value: 'id', align: 'center'},
                { text: 'Picture', value: 'image', align: 'center', width: '15%'},
                { text: 'Name', value: 'name', align: 'center', width: '15%'},
                { text: 'Description', value: 'content', align: 'center'},
                { text: 'Shortcode', value: 'shortcode', align: 'center'},
                { text: 'Action', value: 'action', align: 'center'}
            ],
            selected: [],
            loading: false,
            fields: [
                {
                    name: 'Name', value: 'name'
                },
                {
                    name: 'Description', value: 'description'
                }
            ],
            fieldName: '',
            fieldVal: '',
            editItem: {},
            name: '',
            content: '',
            image: '',
            slug: '',
            currentPage: 1,
            per_page: 30,
            openCreateDialog: false,
            openMedia: false
        }
    },
    computed: {
        ...mapGetters('league', {
            leagues: 'getLeagueList',
            pagination: 'getLeaguePagination'
        })
    },
    created () {
        this.loadPageData();
    },
    methods: {
        ...mapActions('league', ['getLeagueList', 'updateLeague', 'destroyLeague']),
        async loadPageData () {
            try {
                await this.getLeagueList({
                    name: this.name,
                    content: this.content,
                    page: this.currentPage,
                    per_page: this.per_page
                })
            } catch (err) {
                console.log(err)
            }
        },
        handleSearchByField () {
            if (this.fieldVal) {
                this[this.fieldName] = this.fieldVal;
                this.loadPageData();
            }
        },
        handleDeleteMany () {
            const deleteConfirm = confirm('Are you sure want to delete, it might affect to tele sale data ?');

            if (deleteConfirm) {
                const ids = this.selected.map(item => item.id);
                this.loading = true;
                this.destroyLeague({ ids })
                .then(resp => {
                    this.loadPageData()
                    this.loading = false;
                }).catch (err => {
                    this.loading = false;
                    console.log(err)
                })
            }

        },
        handelOpenEditItem(item, index) {
            this.editItem = item;
            this[index] = item[index];

        },
        handleSaveItem (index) {
            let update = {...this.editItem, [index]: this[index] };
            this.loading = true;
            this.updateLeague(update)
                .then(resp => {
                    this.resetPageData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        handleDeleteLeague (id) {
             const deleteConfirm = confirm('Are you sure want to delete?');

            if (deleteConfirm) {
                this.loading = true;
                this.destroyLeague({ ids: [id] })
                .then(resp => {
                    this.loadPageData()
                    this.loading = false;
                }).catch (err => {
                    this.loading = false;
                    console.log(err)
                })
            }
        },
        resetPageData () {
            this.name = '',
            this.image = '',
            this.content = '',
            this.fieldName = '';
            this.fieldVal = '';
            this.loadPageData();
        },
        handleCloseCreateDialog (update) {
            this.openCreateDialog = false;
            if (update) this.loadPageData();
        },
        handleNewPage (currentPage) {
            this.currentPage = currentPage;
            this.loadPageData();
        },
        handleOpenMedia (item) {
            this.editItem = item;
            this.openMedia = true;
        },
        handleCloseMedia (item) {
            this.openMedia = false;
            if (item && item.image_url) {
                this.image = item.image_url;
                this.handleSaveItem('image');
            }

        },
        handleCopyLeagueShortCode (id) {
            if (id) {
                this.$clipboard('[soikeo league_id="'+ id +'"]');
                alert('copy shortcode success')
            }
        }
    }
}
</script>

<style>

</style>

const baseUrl = '/api/gold-price';

export default axios => ({
    getGoldPrice(params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },

    updateGoldPrice(data = {}) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    deleteGoldPrice(data = {}) {
        return axios.post(baseUrl + '/delete', data);
    },
    createGoldPrice (data = {}) {
        return axios.post(baseUrl, data);
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.topLists,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":"","show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Top List")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('div',{staticStyle:{"width":"100px","margin-right":"1rem"}},[_c('v-select',{attrs:{"items":['name', 'domain'],"hide-details":"","dense":""},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline"},on:{"click:append":_vm.handleSearch},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_vm._v(" "),_c('div',{staticStyle:{"width":"100px","margin-left":"1rem"}},[_c('v-select',{attrs:{"items":[{ id: 1, name: 'Active' }, { id: 0, name: 'Inactive' }],"item-text":"name","item-value":"id","hide-details":"","dense":"","label":"Status"},on:{"change":_vm.handleStatusChange},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","disabled":!_vm.selected.length},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create New")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.domain},on:{"update:returnValue":function($event){return _vm.$set(item, "domain", $event)},"update:return-value":function($event){return _vm.$set(item, "domain", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'domain')},"save":function($event){return _vm.handleSaveItem('domain')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Domain"},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=$$v},expression:"domain"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.domain))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.status},on:{"update:returnValue":function($event){return _vm.$set(item, "status", $event)},"update:return-value":function($event){return _vm.$set(item, "status", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'status')},"save":function($event){return _vm.handleSaveItem('status')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-autocomplete',{attrs:{"items":[{ id: 1, name: 'Active' }, { id: 0, name: 'Inactive' }],"label":"Status","item-text":"name","item-value":"id"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"small":"","color":item.status === 1 ? 'primary' : 'red'}},[_vm._v(_vm._s(item.status === 1 ? 'Active' : 'Inactive'))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('TopListItem',{attrs:{"listItems":item.items,"topId":item.id,"saveLoading":_vm.loading},on:{"save-toplist":_vm.handleSaveTopList,"create-toplist":_vm.handleCreateTopList,"destroy-toplist":_vm.handleDestroyTopList}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.handleCopyShortCode(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-code-brackets")]),_vm._v("Copy ShortCode")],1),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"pink","small":""},on:{"click":function($event){return _vm.handleCloneTopList(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-duplicate")]),_vm._v("Clone")],1),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.handleDeleteItem([item.id])}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n               Total: "+_vm._s(_vm.pagination.total)+"\n           ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1),_vm._v(" "),_c('CreateTopListDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.leagues,"hide-default-footer":true,"disable-pagination":"","loading":_vm.loading,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"dark"}},[_c('v-toolbar-title',[_vm._v("Leagues")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex ma-1",staticStyle:{"max-width":"150px"}},[_c('v-autocomplete',{attrs:{"items":_vm.fields,"item-text":"name","item-value":"value","label":"Search Field","hide-details":"","solo":"","outlined":"","dense":"","clear-icon":"mdi-close","clearable":""},model:{value:(_vm.fieldName),callback:function ($$v) {_vm.fieldName=$$v},expression:"fieldName"}})],1),_vm._v(" "),_c('v-text-field',{staticClass:"ma-1",attrs:{"label":"Search","outlined":"","dense":"","hide-details":"","append-icon":"mdi-feature-search-outline","disabled":!_vm.fieldName},on:{"click:append":_vm.handleSearchByField},model:{value:(_vm.fieldVal),callback:function ($$v) {_vm.fieldVal=$$v},expression:"fieldVal"}}),_vm._v(" "),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"success"},on:{"click":_vm.resetPageData}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","disabled":!_vm.selected.length},on:{"click":_vm.handleDeleteMany}},[_vm._v("Delete")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.openCreateDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create New")],1)],1)]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","size":"36"},on:{"click":function($event){return _vm.handleOpenMedia(item)}}},[(item.image)?_c('img',{attrs:{"src":item.image,"alt":""}}):_c('span',{staticClass:"white--text text-h6 text-uppercase"},[_vm._v(_vm._s(item.name.substring(0,2)))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'name')},"save":function($event){return _vm.handleSaveItem('name')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.id},on:{"update:returnValue":function($event){return _vm.$set(item, "id", $event)},"update:return-value":function($event){return _vm.$set(item, "id", $event)},"open":function($event){return _vm.handelOpenEditItem(item, 'content')},"save":function($event){return _vm.handleSaveItem('content')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})]},proxy:true}],null,true)},[_c('v-list-item',[_vm._v(_vm._s(item.content))])],1)]}},{key:"item.shortcode",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.handleCopyLeagueShortCode(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-code-brackets")]),_vm._v(" Copy")],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){return _vm.handleDeleteLeague(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('div',{staticClass:"text-center d-flex align-center justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"16px"},attrs:{"text":""}},[_vm._v("\n               Total: "+_vm._s(_vm.pagination.total)+"\n           ")]),_vm._v(" "),(_vm.pagination.page_count > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pagination.page_count,"total-visible":7},on:{"input":_vm.handleNewPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1),_vm._v(" "),_c('CreateLeagueDialog',{attrs:{"openCreateDialog":_vm.openCreateDialog},on:{"close-create-dialog":_vm.handleCloseCreateDialog}}),_vm._v(" "),_c('Media',{attrs:{"openMedia":_vm.openMedia},on:{"close-media":_vm.handleCloseMedia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const baseUrl = '/api/top-list';
export default axios => ({
    getTopList (params = {}, config = {}) {
        return axios.get(baseUrl, { params });
    },
    updateTopList (data) {
        return axios.put(baseUrl + '/' + data.id, data);
    },
    createTopList (data) {
        return axios.post(baseUrl, data);
    },
    destroyTopList (data = {}) {
        return axios.post(baseUrl + '/delete', data);
    },
    getTopListFolder (params = {}) {
        return axios.get(baseUrl + '/folder', { params });
    },
    saveTopListItem (data) {
        return axios.post(baseUrl + '/items-update', data);
    },
    createTopListItem (data) {
        return axios.post(baseUrl + '/items', data);
    },
    destroyTopListItem (data) {
        return axios.post(baseUrl + '/items-delete', data);
    }
});
